import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import { PriceSlider } from "../../shared/PriceSlider";
import { CommonButton } from "../../shared/CommonButton";
import { CatalogCategoryFilter } from "../CatalogCategoryFilter";
import {
  fetchCategories,
  fetchPriceRange,
  fetchFilteredProducts, fetchProductsByPrice,
} from '../../shared/libs/requests/product';
import "./index.scss";

export const CatalogProductFilter = ({ onApplyFilters }) => {
  const viewportHeight = window.innerHeight;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [priceFilterValues, setPriceFilterValues] = useState([0, 1000]);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [showResetBtn, setShowResetBtn] = useState(false);
  const [isResetCategoryValues, setIsResetCategoryValues] = useState(true);

  // Fetch categories and price range from Medusa
  useEffect(() => {
    const loadFilters = async () => {
      try {
        const [categories, priceData] = await Promise.all([
          fetchCategories(),
          fetchPriceRange(),
        ]);

        setCategoryValues(categories);
        setPriceRange(priceData);
        setPriceFilterValues(priceData);
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    };

    loadFilters();
  }, []);

  const toggleDrawer = (newOpen) => () => setOpenDrawer(newOpen);

  const onChangeFilterValues = (values) => setPriceFilterValues(values);

  const findDetailsWithActiveStatus = (categories) =>
    !categories.some((el) => el.detailList.some((el) => el.isActive));

  const onDetailsChangeHandler = (summaryId, detailId) => {
    const updateCategories = (categories) =>
      categories.map((category) =>
        category.id === summaryId
          ? {
              ...category,
              detailList: category.detailList.map((detail) =>
                detail.id === detailId
                  ? { ...detail, isActive: !detail.isActive }
                  : detail,
              ),
            }
          : category,
      );

    setCategoryValues((prev) => {
      const updatedCategories = updateCategories(prev);
      setIsResetCategoryValues(findDetailsWithActiveStatus(updatedCategories));
      return updatedCategories;
    });
  };

  const resetPriceFilter = () => setPriceFilterValues(priceRange);
  const resetCategoryFilter = () =>
    setCategoryValues(
      categoryValues.map((cat) => ({
        ...cat,
        detailList: cat.detailList.map((detail) => ({
          ...detail,
          isActive: false,
        })),
      })),
    );

  const onResetBtnClick = () => {
    resetPriceFilter();
    resetCategoryFilter();
  };

  const onApplyFilterBtnHandler = async () => {
    const activeCategories = categoryValues.flatMap((category) =>
      category.detailList
        .filter((detail) => detail.isActive)
        .map((detail) => detail.id),
    );

    const filters = {
      price: priceFilterValues,
      categories: activeCategories,
    };

    try {
      const filteredProducts = await fetchFilteredProducts(filters);
      onApplyFilters(filteredProducts);
      toggleDrawer(false)();
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  };

  useEffect(() => {
    const isResetPriceFilter = !priceFilterValues.some(
      (el, index) => el !== priceRange[index],
    );
    setShowResetBtn(!(isResetPriceFilter && isResetCategoryValues));
  }, [categoryValues, priceFilterValues]);

  return (
    <div className="catalog-product-filter">
      <Button className="filter-btn" onClick={toggleDrawer(true)}>
        <TuneIcon />
        Фильтры
      </Button>
      <Drawer
        open={openDrawer}
        className="filter-drawer"
        onClose={toggleDrawer(false)}
      >
        <Box
          style={{ height: `${viewportHeight - 48}px` }}
          role="presentation"
          className="filter-drawer-container"
        >
          <div className="filter-drawer__title-box">
            <div className="filter-reset-button">
              <span className="main-title">Фильтры</span>
              {showResetBtn && (
                <span className="reset-btn" onClick={onResetBtnClick}>
                  сбросить
                </span>
              )}
            </div>
            <Button onClick={toggleDrawer(false)}>
              <CloseIcon />
            </Button>
          </div>
          <PriceSlider
            filterDefaultValues={priceRange}
            filterValues={priceFilterValues}
            onChangeValues={onChangeFilterValues}
          />
          <CatalogCategoryFilter
            categoryValues={categoryValues}
            onDetailsChange={onDetailsChangeHandler}
          />
          <CommonButton
            label="Показать товары"
            onClickHandler={onApplyFilterBtnHandler}
          />
        </Box>
      </Drawer>
    </div>
  );
};
