import axios from "axios";

export const createOrder = async (orderData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_MEDUSA_BACKEND_URL}/store/orders`,
      {
        email: orderData.email,
        customer_id: orderData.customer_id,
        shipping_address: orderData.shipping_address,
        items: orderData.items,
        total: orderData.total,
      },
      {
        headers: {
          Authorization: `Bearer ${orderData.token}`, // Токен аутентификации
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Ошибка при создании заказа:", error.response?.data || error.message);
    throw error;
  }
};
