import React from 'react';
import { Slider } from '@mui/material';
import PropTypes, { number } from 'prop-types';

import './index.scss';

export const PriceSlider = ({ filterDefaultValues, filterValues, onChangeValues }) => {
  const handleChange = (event, newValue) => onChangeValues(newValue);

  return (
    <div className='price-slider'>
      <div className='price-slider__side-values'>
        <span>от {filterValues[0]} &#8381;</span>
        <span>до {filterValues[1]} &#8381;</span>
      </div>
      <Slider
        getAriaLabel={() => 'Price range'}
        value={filterValues}
        min={filterDefaultValues[0]}
        max={filterDefaultValues[1]}
        onChange={handleChange}
        valueLabelDisplay='auto'
      />
    </div>
  );
};

PriceSlider.propTypes = {
  filterDefaultValues: PropTypes.arrayOf(number).isRequired,
  filterValues: PropTypes.arrayOf(number).isRequired,
  onChangeValues: PropTypes.func.isRequired,
};
