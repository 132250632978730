export const IconRussianFlag = () => (
  <svg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_677_643"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="11"
    >
      <path
        d="M14.6786 0H1.82143C1.22969 0 0.75 0.479695 0.75 1.07143V9.64286C0.75 10.2346 1.22969 10.7143 1.82143 10.7143H14.6786C15.2703 10.7143 15.75 10.2346 15.75 9.64286V1.07143C15.75 0.479695 15.2703 0 14.6786 0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_677_643)">
      <path
        d="M14.6784 0.133789H1.82129C1.30352 0.133789 0.883789 0.553522 0.883789 1.07129V9.64272C0.883789 10.1605 1.30352 10.5802 1.82129 10.5802H14.6784C15.1962 10.5802 15.6159 10.1605 15.6159 9.64272V1.07129C15.6159 0.553522 15.1962 0.133789 14.6784 0.133789Z"
        fill="white"
        stroke="#F5F5F5"
        strokeWidth="14.2857"
      />
      <mask
        id="mask1_677_643"
        maskUnits="userSpaceOnUse"
        x="-7"
        y="-8"
        width="30"
        height="26"
      >
        <path
          d="M14.6784 0.133789H1.82129C1.30352 0.133789 0.883789 0.553522 0.883789 1.07129V9.64272C0.883789 10.1605 1.30352 10.5802 1.82129 10.5802H14.6784C15.1962 10.5802 15.6159 10.1605 15.6159 9.64272V1.07129C15.6159 0.553522 15.1962 0.133789 14.6784 0.133789Z"
          fill="white"
          stroke="white"
          strokeWidth="14.2857"
        />
      </mask>
      <g mask="url(#mask1_677_643)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 7.1427H15.75V3.57129H0.75V7.1427Z"
          fill="#0C47B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 10.714H15.75V7.14258H0.75V10.714Z"
          fill="#E53B35"
        />
      </g>
    </g>
  </svg>
);
