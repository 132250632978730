import { setUser } from "../../app/userSlice";
import { setLocalItem } from "./localStorage";

export const saveUserInfo = (customer, dispatch) => {
  dispatch(setUser(customer));
  setLocalItem("user", customer);
};

export const formatPrice = (amount) => {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB", // Укажите валюту региона
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount); // Преобразуем копейки в основную валюту
};