import React, { useEffect } from "react";
import { MedusaProvider } from "medusa-react";
import { QueryClient } from "@tanstack/react-query";
import { Routing } from "../pages";
import { createTheme, ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setUnSavedAddress, setUser } from "./userSlice";
import { getLocalItem } from "../shared/libs/localStorage";
import { addCustomerAddress } from "../shared/libs/requests/user";
import { saveUserInfo } from "../shared/libs/helpers";
import { setCart } from "./shopSlice";

import "./index.css";

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const App = () => {
  const dispatch = useDispatch();
  const unSavedAddress = useSelector((state) => state.user.unSavedAddress);
  const userStore = useSelector((state) => state.user.user);

  // Сохраняет в store из LocalStorage
  useEffect(() => {
    try {
      const userStorage = getLocalItem("user");
      const cartStorage = getLocalItem("cart");

      if (userStorage) dispatch(setUser(userStorage));
      if (cartStorage) dispatch(setCart(cartStorage));
    } catch (e) {
      console.error(e);
    }
  }, []);

  // Сохраняет введеный адрес юзера после авторизации/регистрации
  useEffect(() => {
    const saveUserAddress = async () => {
      try {
        const customer = await addCustomerAddress({
          ...unSavedAddress,
          is_default_billing: true,
          is_default_shipping: true,
        });
        dispatch(setUnSavedAddress(null));
        saveUserInfo(customer, dispatch);
      } catch (e) {
        console.error(e);
      }
    };
    if (unSavedAddress && userStore) saveUserAddress();
  }, [userStore]);

  return (
    <div className="App">
      <MedusaProvider
        queryClientProviderProps={{ client: queryClient }}
        baseUrl={process.env.REACT_APP_MEDUSA_BACKEND_URL}
        publishableApiKey={process.env.REACT_APP_PUBLISH_API_KEY}
      >
        <ThemeProvider theme={theme}>
          <Routing />
        </ThemeProvider>
      </MedusaProvider>
    </div>
  );
};

export default App;
