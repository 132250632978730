import React, { useState, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { CommonButton } from '../../shared/CommonButton';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { RoundedButton } from '../../shared/RoundedButton';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { setShowAddressForm, setShowAuthForm, setUnSavedAddress } from '../../app/userSlice';
import { addCustomerAddress, updateCustomerAddress } from '../../shared/libs/requests/user';
import { saveUserInfo } from '../../shared/libs/helpers';

import './index.scss';
import { getRequest } from '../../shared/libs/requests';
import { addCDEKOfficeToCart, updateCart } from '../../shared/libs/requests/cart';
import { setCart } from '../../app/shopSlice';
import { setLocalItem } from '../../shared/libs/localStorage';

export const HeaderRegionsForm = () => {
  const dispatch = useDispatch();
  const viewportHeight = window.innerHeight;
  const showAddressForm = useSelector((state) => state.user.showAddress);
  const [openDrawer, setOpenDrawer] = useState(showAddressForm);
  const [citiesList, setCitiesList] = useState([]);
  const [streetsList, setStreetsList] = useState([]);
  const [pickUpPointsList, setPickUpPointsList] = useState([]);
  const [cityId, setCityId] = useState('');
  const [selectedCity, setSelectedCity] = useState(''); // Хранит выбранный город
  const [selectedAddress, setSelectedAddress] = useState(''); // Хранит выбранный адрес (улица)
  const [selectedPickUpPoint, setSelectedPickUpPoint] = useState(''); // Хранит выбранный пвз
  const userStore = useSelector((state) => state.user.user);
  const cartStore = useSelector((state) => state.shop.cart);

  const closeDrawer = () => {
    setCityId('');
    setCitiesList([]);
    setStreetsList([]);
    setSelectedCity('');
    setSelectedAddress('');
    setSelectedPickUpPoint('');
    dispatch(setShowAddressForm(null));
  };

  const toggleDrawer = (newOpen) => {
    setOpenDrawer(newOpen);
    if (!newOpen) closeDrawer();
  };
  console.log('cartStore', cartStore);
  const onApplyFilterBtnHandler = async () => {
    const data = {
      city: selectedPickUpPoint?.city,
      address_1: selectedPickUpPoint?.address,
      postal_code: selectedPickUpPoint?.postal_code,
      // billing_address: selectedPickUpPoint?.address,
      country_code: 'ru',
      metadata: {
        ...selectedPickUpPoint,
      },
      ...(!userStore?.addresses?.length
        ? {
            is_default_billing: true,
            is_default_shipping: true,
          }
        : null), // Добавляем дефолтный адрес только для самого первого адреса
    };

    if (userStore?.id) {
      try {
        if (showAddressForm === 'new') {
          console.log('data', data);
          const customer = await addCustomerAddress(data);
          saveUserInfo(customer, dispatch);
        } else {
          const customer = await updateCustomerAddress(getDefaultAddress()?.id, data);
          saveUserInfo(customer, dispatch);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('Сохраняем временный адрес:', data);
      dispatch(setUnSavedAddress(data));
      dispatch(setShowAuthForm('login'));
    }

    toggleDrawer(false);
  };

  const fetchCities = async (locationValue) => {
    try {
      const url = `https://catalog.api.2gis.com/2.0/region/search?q=${locationValue}&key=5df490c3-58d9-4fff-953b-96e5851b97fa`;
      let response = await fetch(url);
      let commits = await response.json();
      const items = commits?.result?.items || [];
      setCitiesList(items);
      if (items.length) {
        setCityId(items[0]?.id);
      }
    } catch (error) {
      console.error('Failed to fetch cities', error);
    }
  };

  const fetchStreets = async (locationValue) => {
    try {
      const url = `https://catalog.api.2gis.com/3.0/suggests?q=${locationValue.trim()}&region_id=${cityId}&key=5df490c3-58d9-4fff-953b-96e5851b97fa&suggest_type=address&fields=items.address`;
      let response = await fetch(url);
      let commits = await response.json();
      setStreetsList(commits?.result?.items || []);
    } catch (error) {
      console.error('Failed to fetch streets', error);
    }
  };

  // const fetchPickUpPoints = async (latitude, longitude) => {
  //   const url = `https://catalog.api.2gis.com/3.0/items?q=CDEK&point=${longitude},${latitude}&key=5df490c3-58d9-4fff-953b-96e5851b97fa&radius=500&fields=city`;
  //   let response = await fetch(url);
  //   let data = await response.json();
  //   setPickUpPointsList(data?.result?.items || []);
  // };

  // const fetchPickUpPoints = async (postCode) => {
  //   try {
  //     // const url = `/store/delivery/cdek-offices?limit=5&postal_code=${postCode}`;
  //     const url = `/store/delivery/cdek-offices?limit=45&postal_code=${postCode}`;
  //     let response = await getRequest(url);
  //     let data = await response.json();
  //     console.log('data', data);
  //     setPickUpPointsList(data?.result?.items || []);
  //   } catch (error) {
  //     console.error('Failed to fetch pick up points', error);
  //   }
  // };

  // Дебаунс для ввода города
  const debouncedFetchCities = useCallback(
    debounce((locationValue) => {
      fetchCities(locationValue);
    }, 300),
    [],
  );

  // Дебаунс для ввода улицы
  const debouncedFetchStreets = useCallback(
    debounce((locationValue) => {
      fetchStreets(locationValue);
    }, 300),
    [cityId],
  );

  const getDefaultAddress = () => {
    if (showAddressForm === 'new' || !userStore?.addresses?.length) return null;
    return userStore?.addresses?.find((el) => el.is_default_shipping);
  };

  // const isApplyDisabled = () => !(streetsList?.length === 1 && streetsList?.find((el) => el?.address?.postcode));
  // const isApplyDisabled = () => !(streetsList?.length === 1 && selectedPickUpPoint);
  const isApplyDisabled = () => !selectedPickUpPoint?.postal_code;

  const sanitizeStreetValue = (value) => value.replace(/[.,\/#!$%\^&\*;:{}=_`'"<>~]/g, '');
  const getSelectedStreetWithPostalCode = () => {
    console.log('1streetsList', streetsList);
    console.log('1selectedAddress', selectedAddress);
    return streetsList.find((street) => sanitizeStreetValue(street?.full_name) === selectedAddress.trim());
  };

  const handleOpenDrawer = () => {
    if (!getDefaultAddress()?.address_1) dispatch(setShowAddressForm('new'));
    toggleDrawer(true);
  };

  useEffect(() => {
    const defaultAddress = getDefaultAddress();
    console.log('defaultAddress', defaultAddress);
    if (openDrawer && defaultAddress) {
      setSelectedCity(defaultAddress?.city);
      setSelectedAddress(defaultAddress?.address_1);
      // добавлять pickUpPoint из address
    }
  }, [openDrawer]);

  useEffect(() => {
    if (showAddressForm) setOpenDrawer(showAddressForm);
  }, [showAddressForm]);

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (!isOpen) return;

    const initCDEKWidget = () => {
      try {
        new window.CDEKWidget({
          from: 'Москва',
          root: 'cdek-map',
          apiKey: '7ff49c8c-bfae-4650-aa36-5201bf62c095',
          servicePath: 'https://api.zenhealth.ru/cdek-widget/service.php',
          defaultLocation: 'Москва',
          hideDeliveryOptions: {
            office: false,
            door: true,
          },
          onReady() {},
          onCalculate() {
            alert('Расчет стоимости доставки произведен');
          },
          onChoose(deliveryType, paymentType, address) {
            setSelectedPickUpPoint(address);
            closeModal(); // Закрываем модалку после выбора доставки
          },
        });
      } catch (error) {
        console.error('Ошибка инициализации CDEKWidget:', error);
      }
    };

    setTimeout(initCDEKWidget, 100);
  }, [isOpen]);

  return (
    <div>
      <div className="regions-form">
        <RoundedButton className="regions-form__btn" onClick={handleOpenDrawer}>
          <PlaceOutlinedIcon />
          {getDefaultAddress()?.address_1 || 'Адрес'}
        </RoundedButton>
        <Drawer open={openDrawer} className="regions-drawer" onClose={() => toggleDrawer(false)}>
          <Box style={{ height: `${viewportHeight - 48}px` }} role="presentation" className="regions-drawer-container">
            <div className="regions-drawer__title-box">
              <span className="main-title">Адрес доставки DEMO v.1.1</span>
              <Button onClick={() => toggleDrawer(false)}>
                <CloseIcon />
              </Button>
            </div>
            <div className="regions-drawer__forms">
              <Stack spacing={4}>
                {/* <Autocomplete
                  id="city"
                  disableClearable
                  onInputChange={(e, value) => debouncedFetchCities(value)}
                  onChange={(e, value) => setSelectedCity(value)}
                  value={selectedCity} // Привязка текущего значения
                  noOptionsText="пусто"
                  options={citiesList.map((option) => option?.name || '')}
                  renderInput={(params) => <TextField {...params} label="Город" variant="standard" />}
                />
                <Autocomplete
                  id="street"
                  disableClearable
                  disabled={!cityId}
                  onInputChange={(e, value) => debouncedFetchStreets(sanitizeStreetValue(value))}
                  onChange={(e, value) => setSelectedAddress(value.trim())}
                  value={selectedAddress.trim()} // Привязка текущего значения
                  noOptionsText="пусто"
                  options={streetsList.map((option) => sanitizeStreetValue(option?.full_name?.trim()) || '')}
                  renderInput={(params) => <TextField {...params} label="Улица и дом" variant="standard" />}
                /> */}
                {/* <Typography variant="h6">Выберите пункт выдачи для доставки</Typography> */}
                <TextField
                  id="outlined-basic"
                  label="Адрес ПВЗ"
                  variant="outlined"
                  value={
                    selectedPickUpPoint?.city ? `${selectedPickUpPoint?.city}, ${selectedPickUpPoint?.address}` : 'Здесь будет адрес ПВЗ'
                  }
                  readOnly
                />
                {/* <Autocomplete
                  id="pick-up-point"
                  disableClearable
                  // disabled={!cityId || !selectedAddress.trim() || !getSelectedStreetWithPostalCode()?.address?.postcode}
                  disabled={!selectedPickUpPoint}
                  onChange={(e, value) => setSelectedPickUpPoint(value.trim())}
                  value={selectedPickUpPoint.trim()} // Привязка текущего значения
                  noOptionsText="пусто"
                  options={pickUpPointsList.map((option) => `CDEK - ${sanitizeStreetValue(option?.address_name?.trim())}` || '')}
                  renderInput={(params) => <TextField {...params} label="Адрес ПВЗ" variant="standard" />}
                /> */}
              </Stack>
              <RoundedButton className="regions-drawer__btn" onClick={openModal}>
                Выбрать ПВЗ
              </RoundedButton>
              {isOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <button className="close-button" onClick={closeModal}>
                      <CloseIcon />
                    </button>
                    <div id="cdek-map" style={{ width: '100%', height: '500px' }}></div>
                  </div>
                </div>
              )}
            </div>
            <CommonButton
              label={getDefaultAddress() ? 'Сохранить' : 'Привезти сюда'}
              disabled={isApplyDisabled()}
              onClickHandler={onApplyFilterBtnHandler}
            />
          </Box>
        </Drawer>
      </div>
    </div>
  );
};
