// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-card-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 600px;
}

.catalog-subcategory {
  position: absolute;
  top: 0;
  left: 300px;
  width: 300px;
  padding: 6px 16px;
}
.catalog-subcategory .MuiListItemText-root {
  margin: 2.5px 0 !important;
}
.catalog-subcategory span {
  font-size: 14px !important;
  color: rgb(128, 128, 128) !important;
  font-weight: 500 !important;
  line-height: 12px;
  text-align: left;
}
.catalog-subcategory span.hovered {
  color: black;
}

.catalog-main-category {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 16px;
  text-align: left;
}
.catalog-main-category .MuiListItemButton-root {
  padding: 8px 16px !important;
}
.catalog-main-category.hovered {
  color: black !important;
}

.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
  top: 430px !important;
  left: calc(50% - 8px) !important;
  max-width: 1154px;
  width: 100%;
  height: 746px;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/features/HeaderFavoriteForm/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AACF;AACE;EACE,0BAAA;AACJ;AAEE;EACE,0BAAA;EACA,oCAAA;EACA,2BAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,YAAA;AAAN;;AAKA;EACE,0BAAA;EACA,2BAAA;EACA,iBAAA;EACA,gBAAA;AAFF;AAIE;EACE,4BAAA;AAFJ;AAKE;EACE,uBAAA;AAHJ;;AAoBA;EACE,qBAAA;EACA,gCAAA;EACA,iBAAA;EACA,WAAA;EACA,aAAA;EACA,gCAAA;AAjBF","sourcesContent":[".catalog-card-container {\n  display: flex;\n  position: absolute;\n  top: 0;\n  left: 600px;\n}\n\n.catalog-subcategory {\n  position: absolute;\n  top: 0;\n  left: 300px;\n  width: 300px;\n  padding: 6px 16px;\n\n  .MuiListItemText-root {\n    margin: 2.5px 0!important;\n  }\n\n  & span {\n    font-size: 14px!important;\n    color: rgb(128, 128, 128)!important;\n    font-weight: 500!important;\n    line-height: 12px;\n    text-align: left;\n\n    &.hovered {\n      color: black;\n    }\n  }\n}\n\n.catalog-main-category {\n  font-size: 16px!important;\n  font-weight: 500!important;\n  line-height: 16px;\n  text-align: left;\n\n  .MuiListItemButton-root {\n    padding: 8px 16px!important;\n  }\n\n  &.hovered {\n    color: black!important;\n\n    //&:after {\n    //  border: 6px solid;\n    //  border-color: transparent transparent transparent black;\n    //}\n  }\n\n  //&:after {\n  //  content: \"\";\n  //  border: 6px solid;\n  //  position: absolute;\n  //  left: 270px;\n  //  border-color: transparent transparent transparent rgb(128, 128, 128);\n  //}\n}\n\n.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {\n  top: 430px !important;\n  left: calc(50% - 8px) !important;\n  max-width: 1154px;\n  width: 100%;\n  height: 746px;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
