import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "@mui/material";

import "./index.scss";

export const ProfileUserFAQSection = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="profile-user-faq-container">
      <div className="user-faq-navbar-container">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography className="accordion-title">о заказе</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Link href="#how-make-order-subtitle">как сделать заказ</Link>
            </Typography>
            <Typography>
              <Link href="#payment-failed-subtitle">
                не удалось оплатить заказ. что делать?
              </Link>
            </Typography>
            <Typography>
              <Link href="#order-split-subtitle">
                почему заказ разделился на несколько посылок?
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography className="accordion-title">
              отмена и возврат
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography className="accordion-title">доставка</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography className="accordion-title">оплата</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography className="accordion-title">
              подарочные карты
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6d-content"
            id="panel6d-header"
          >
            <Typography className="accordion-title">
              программа лояльности
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7d-content"
            id="panel7d-header"
          >
            <Typography className="accordion-title">
              как получить скидку
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8d-content"
            id="panel8d-header"
          >
            <Typography className="accordion-title">
              у меня уже есть карта. как ей воспользоваться?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9d-content"
            id="panel9d-header"
          >
            <Typography className="accordion-title">
              персональные данные
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10d-content"
            id="panel10d-header"
          >
            <Typography className="accordion-title">отзывы</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11d-content"
            id="panel11d-header"
          >
            <Typography className="accordion-title">обратная связь</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12d-content"
            id="panel12d-header"
          >
            <Typography className="accordion-title">
              гарантия качества
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Lorem</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="user-faq-content-container">
        <Typography variant="h3">О заказе</Typography>

        <Typography variant="h5" id="how-make-order-subtitle">
          Как сделать заказ
        </Typography>
        <Typography variant="body2">
          1. Добавьте нужные товары в корзину. Перейдите к оформлению заказа.
        </Typography>
        <Typography variant="body2">
          2. Выберите адрес и способ доставки.
        </Typography>
        <Typography variant="body2">3. Укажите получателя.</Typography>
        <Typography variant="body2">4. Выберите способ оплаты.</Typography>
        <Typography variant="body2">5. Оплатите заказ.</Typography>

        <Typography variant="h5" id="payment-failed-subtitle">
          Не удалось оплатить заказ. Что делать?
        </Typography>
        <Typography variant="body2">
          Если при оплате произошла ошибка, подождите или используйте другой
          способ оплаты. Бонусы, промокод или подарочная карта восстановятся в
          течение 30 минут.
        </Typography>
        <Typography variant="body2">
          Если были списаны денежные средства, напишите в онлайн-чат WhatsApp,
          Telegram или позвоните по телефону 8 800 770 70 21. Специалисты на
          связи 24/7.
        </Typography>
        <Typography variant="h5" id="order-split-subtitle">
          Почему заказ разделился на несколько посылок?
        </Typography>
        <Typography variant="body2">
          Если мы можем доставить часть заказа быстрее, предложим разделить его
          на несколько посылок. В этом случае бесплатная доставка действует при
          общей сумме от 1 500 рублей. Если сумма меньше — доставка платная.
        </Typography>
        <Typography variant="body2">
          Если хотите получить все товары вместе, можно объединить их в одну
          посылку, но это не всегда возможно.
        </Typography>
        <Typography variant="h5">
          Заказ не отображается в личном кабинете
        </Typography>
        <Typography variant="body2">
          Заказ появится через 30–60 минут после оформления. Его статус будет
          обновляться в зависимости от сборки и отправки. Если заказ с
          курьерской доставкой, номер курьера станет доступен после передачи
          заказа.
        </Typography>
        <Typography variant="h5">
          Как изменить адрес/способ доставки?
        </Typography>
        <Typography variant="body2">
          Изменить адрес можно до статуса «выполнен» при курьерской доставке. В
          некоторых службах доступна смена способа доставки с курьера на
          самовывоз и наоборот, например: DPD, Почта РФ, СДЭК.
        </Typography>
        <Typography variant="h5">
          Хочу поменять или убрать товар из заказа
        </Typography>
        <Typography variant="body2">
          Убрать или заменить товар нельзя. Заказ нужно отменить и оформить
          новый. Деньги возвращаются быстро, а бонусы появятся в личном кабинете
          через 30 минут.
        </Typography>
        <Typography variant="h5">Как изменить дату/время доставки?</Typography>
        <Typography variant="body2">
          Свяжитесь с call-центром. Изменить дату можно, если заказ ещё не
          передан курьеру. При изменениях с нашей стороны, уведомим через email,
          push или sms.
        </Typography>
        <Typography variant="h5">Можно ли изменить способ доставки?</Typography>
        <Typography variant="body2">
          Изменить способ доставки нельзя, но можно отменить заказ и оформить
          новый. Бонусы и промокоды восстанавливаются через 30 минут, подарочная
          карта — через 1-2 дня.
        </Typography>
        <Typography variant="h5">Как продлить срок хранения заказа?</Typography>
        <Typography variant="body2">
          Срок хранения можно продлить при самовывозе или доставке по клику. Для
          этого свяжитесь с call-центром.
        </Typography>
        <Typography variant="h5">
          Как изменить имя или номер получателя?
        </Typography>
        <Typography variant="body2">
          Свяжитесь с call-центром с номера, на который был оформлен заказ.
        </Typography>
        <Typography variant="h5">
          Может ли другой человек получить мой заказ?
        </Typography>
        <Typography variant="body2">
          Да, при наличии номера заказа или кода получения, который придет в
          уведомлении.
        </Typography>
        <Typography variant="h5">Как связаться с курьером?</Typography>
        <Typography variant="body2">
          Номер курьера будет доступен после передачи заказа в доставку. Об этом
          сообщим через push-уведомление.
        </Typography>
        <Typography variant="h5">Почему изменилась сумма заказа?</Typography>
        <Typography variant="body2">
          Состав заказа может измениться, если товары закончились на складе.
          Деньги за отсутствующие товары вернутся в течение 2–3 дней.
        </Typography>
      </div>
    </div>
  );
};
