import React, { useMemo } from 'react';
import { RoundedButton } from '../../shared/RoundedButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatPrice,
  saveUserInfo,
  truncateText,
} from '../../shared/libs/helpers';
import { updateCustomer } from '../../shared/libs/requests/user';

import './index.scss';
import { openAuthFormForUnauthorizedActions } from '../../shared/libs/navigate';

export const CatalogProductCard = ({ product, xs, addToCartHandler, showUpButton }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user.user);

  const onProductNavigate = () => navigate(`/product/${product.id}`);

  const addFavouriteHandler = async () => {
    if (!userStore?.id) {
      openAuthFormForUnauthorizedActions();
      return;
    }

    const updatedFavList = userStore.metadata.favoriteItems
      ? userStore.metadata.favoriteItems.includes(product?.id)
        ? userStore.metadata.favoriteItems.filter((el) => el !== product?.id)
        : [...userStore.metadata.favoriteItems, product?.id]
      : [product?.id];

    //добавлено с целью быстрого отклика
    saveUserInfo(
      { ...userStore, metadata: { favoriteItems: updatedFavList } },
      dispatch
    );

    const { error, customer } = await updateCustomer({
      metadata: { favoriteItems: updatedFavList },
    });

    if (error) {
      saveUserInfo(
        {
          ...userStore,
          metadata: { favoriteItems: userStore.metadata.favoriteItems },
        },
        dispatch
      );
    }
  };

  const selectedVariantPrice = useMemo(() => {
    if (product?.variants?.length) {
      return product.variants.find((el) => el.calculated_price); // Берём вариант у которого есть цен как дефолтный
    }
    return null;
  }, [product]);

  const price = selectedVariantPrice?.calculated_price?.calculated_amount
    ? formatPrice(selectedVariantPrice.calculated_price.calculated_amount)
    : 'N/A';

  const originalPrice = selectedVariantPrice?.calculated_price?.original_amount
    ? formatPrice(selectedVariantPrice.calculated_price.original_amount)
    : null;

  return (
    <Grid item xs={xs} spacing={4} className="catalog-product-card">
      {!userStore?.metadata?.favoriteItems?.includes(product?.id) ? (
        <FavoriteBorder
          className="add-to-favorite-btn"
          onClick={addFavouriteHandler}
        />
      ) : (
        <Favorite
          className="add-to-favorite-btn"
          onClick={addFavouriteHandler}
        />
      )}
      <div
        className="catalog-product-card__image-container"
        onClick={onProductNavigate}
      >
        <span
          style={{ backgroundImage: `url(${product?.images?.[0]?.url})` }}
        />
      </div>
      <p className="catalog-product-card__section" onClick={onProductNavigate}>
        {product?.description}
      </p>
      <p className="catalog-product-card__title" onClick={onProductNavigate}>
        {truncateText(product?.title, 20)}
      </p>
      <div className="catalog-product-card__button-box">
        <RoundedButton onClick={() => addToCartHandler(product)}>
          <ShoppingCartIcon />
          <span>{price}</span>
        </RoundedButton>
      </div>
    </Grid>
  );
};
