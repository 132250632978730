import React from "react";
import { IconLogo } from "../../shared/IconLogo";
import { IconRussianFlag } from "../../shared/IconRussianFlag";
import { IconTelegram } from "../../shared/IconTelegram";
import { IconVK } from "../../shared/IconVK";
import { IconYoutube } from "../../shared/IconYoutube";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link } from "@mui/material";

import mirImage from "../../media/mir-card.png";
import visaImage from "../../media/visa-card.png";
import mastercardImage from "../../media/master-card.png";
import "./index.scss";

export const FooterPanel = () => {
  return (
    <div className="footer">
      <div className="main-container">
        <div className="logo-container">
          <IconLogo isInverse />
        </div>
        <hr className="divider" />
        <div className="info-container">
          <div className="info-container col">
            <div className="social-media">
              <div className="county">
                <IconRussianFlag />
                <p>Россия</p>
              </div>
              <ul className="social-links">
                <li>
                  <IconTelegram />
                </li>
                <li>
                  <IconVK />
                </li>
                <li>
                  <IconYoutube />
                </li>
              </ul>
            </div>
            <div className="company-info">
              <p className="title">
                Интернет-магазин товаров для здоровья, косметики, парфюмерии
              </p>
              <p className="subtitle">О компании</p>
              <p className="text">
                В интернет-магазине Zenhealth.ru представлены лучшие коллекции
                товаров для здоровья, а также косметики и парфюмов ведущих
                мировых брендов. Любая покупка на сайте Zenhealth.ru происходит
                в два клика. Покупатель может быть уверен, что получит фирменный
                продукт превосходного качества с гарантией. Для постоянных
                клиентов существует программа лояльности. Кроме того, на
                Zenhealth.ru регулярно проводятся распродажи товаров из новых
                коллекций.
              </p>
              <p className="subtitle">Оплата и возврат</p>
              <p className="text">
                Для удобства клиентов оплатить товар можно любым способом:
                наличными курьеру, по банковской карте онлайн. У каждого курьера
                Lamoda.ru есть мобильный терминал оплаты, поэтому платить
                карточкой можно даже дома в момент доставки заказа. Возврат
                товара возможен в течение 14 дней после покупки.
              </p>
            </div>
          </div>
          <div className="info-container col">
            <ul className="pages-list">
              <li>
                <p>Помощь</p>
                <button>Мои заказы</button>
                <button>Условия доставки</button>
                <button>Возврат</button>
                <button>Как оформить заказ</button>
                <button>Часто задаваемые вопросы</button>
              </li>
              <li>
                <p>Политика компании</p>
                <Link href="/privacy-policy" target="_blank">
                  Политика обработки персональных данных
                </Link>
                <Link href="/data-processing-consent" target="_blank">
                  Согласие на обработку персональных данных
                </Link>
                <Link href="/user-agreement" target="_blank">
                  Пользовательское соглашение
                </Link>{" "}
              </li>
              <li>
                <p>Партнерам</p>
                <button>Начать продавать на Zenhealth</button>
              </li>
            </ul>
          </div>
        </div>
        <div className="payment-container">
          <div className="payment-col">
            <p>Контакты:</p>
            <ul>
              <li className="company-contacts">
                <EmailIcon />
                info@zenhealth.ru
              </li>
              <li className="company-contacts">
                <PhoneIcon />
                +7 (993) 252-64-77
              </li>
            </ul>
          </div>
          <div className="payment-col">
            <p>Способы оплаты:</p>
            <div className="payment-icons">
              <img src={mirImage} alt="mir card" />
              <img src={visaImage} alt="visa card" />
              <img src={mastercardImage} alt="mastercard" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
