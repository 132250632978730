import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { Link, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { i18n } from "../../app/verbs";
import { setShowAuthForm, setUser } from "../../app/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { customerLoginHandler } from "../../shared/libs/requests/auth";
import { setLocalItem } from "../../shared/libs/localStorage";
import { saveUserInfo } from "../../shared/libs/helpers";

import "./index.scss";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const showAuthForm = useSelector((state) => state.user.showAuth);

  const navigate = useNavigate();
  const [serverError, setServerError] = useState("");

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(i18n.validation.email.invalid)
      .required(i18n.validation.email.required),
    password: Yup.string()
      .min(8, i18n.validation.password.minLength)
      .required(i18n.validation.password.required),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
  });

  const toggleDrawer = (newOpen) => () => dispatch(setShowAuthForm(newOpen));

  const handleLogin = async () => {
    formik.setTouched({
      email: true,
      password: true,
    });

    // Проверить ошибки формы
    const errors = await formik.validateForm();

    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      const { token, customer } = await customerLoginHandler(
        formik.values.email,
        formik.values.password,
      );

      // Успешная регистрация
      setLocalItem("token", token);
      saveUserInfo(customer, dispatch);
      // Диспатч и навигация
      dispatch(setShowAuthForm(null));
      navigate("/profile");
    } catch (error) {
      console.error("Ошибка при запросе:", error.message);
    }
  };

  return (
    <div className="login-form">
      <Drawer
        open={showAuthForm === "login"}
        anchor="right"
        className="filter-drawer"
        onClose={toggleDrawer("")}
      >
        <Box
          role="presentation"
          className="filter-drawer-container"
          sx={{ width: "348px", gap: "unset" }}
        >
          <Typography className="login-form__title" variant="h5" gutterBottom>
            Вход
          </Typography>
          {serverError && (
            <Typography variant="body2" color="error" gutterBottom>
              {serverError}
            </Typography>
          )}
          <TextField
            fullWidth
            id="email"
            name="email"
            size="small"
            label="Электронная почта"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Пароль"
            type="password"
            size="small"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            margin="normal"
          />
          <Typography
            variant="body2"
            fontSize="12px"
            color="gray"
            sx={{ mt: 2 }}
          >
            Нажимая на кнопку "Войти", я подтверждаю свое согласие на обработку
            моих персональных данных в соответствии с{" "}
            <Link href="/privacy-policy" target="_blank">
              Политикой обработки персональных данных
            </Link>
            ,{" "}
            <Link href="/data-processing-consent" target="_blank">
              Согласием на обработку персональных данных
            </Link>
            , а также в соответствии с{" "}
            <Link href="/user-agreement" target="_blank">
              Пользовательским соглашением
            </Link>
            .
          </Typography>
          <Button
            color="primary"
            variant="contained"
            className="login-form__submit-btn"
            fullWidth
            type="submit"
            sx={{ mt: 2 }}
            onClick={handleLogin}
          >
            Войти
          </Button>
          <Button
            color="inherit"
            fullWidth
            className="login-form__submit-btn"
            sx={{ mt: 1 }}
            onClick={() => dispatch(setShowAuthForm("create"))}
          >
            Зарегистрироваться
          </Button>
        </Box>
      </Drawer>
    </div>
  );
};
