import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import PropTypes from 'prop-types';

import './index.scss';

export const CatalogCategoryFilter = ({ categoryValues, onDetailsChange }) => {
  return (
    <div className='category-filter'>
      {categoryValues?.map((category) => (
        <Accordion key={category.summaryTitle}>
          <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
            <Typography>{category.summaryTitle}</Typography>
          </AccordionSummary>
          {category.detailList?.map((el) => (
            <AccordionDetails key={el.label}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={el.label}
                  checked={el.isActive}
                  onChange={() => onDetailsChange(category.id, el.id)}
                />
              </FormGroup>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </div>
  );
};

CatalogCategoryFilter.propTypes = {
  categoryValues: PropTypes.arrayOf(
    PropTypes.shape({
      summaryTitle: PropTypes.string.isRequired,
      detailList: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          isActive: PropTypes.bool.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  ).isRequired,
  onDetailsChange: PropTypes.func.isRequired,
};
