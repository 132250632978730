import { deleteRequest, getRequest, postRequest } from './index';

export const createCart = async (items = []) => {
  const cartBody = {
    items,
  };

  const { cart } = await postRequest('/store/carts', cartBody);
  return cart;
};

export const updateCart = async (cartId, params) => {
  const { cart } = await postRequest(`/store/carts/${cartId}`, params);
  return cart;
};

export const addPromotionToCart = async (cartId, params) => {
  const data = await postRequest(`/store/carts/${cartId}/promotions`, params);
  return data;
}

export const completeCart = async (cartId, params) => {
  const { cart } = await postRequest(`/store/carts/${cartId}/complete`, params);
  return cart;
};

export const addLineItemToCart = async (cartId, updates) => {
  const { cart } = await postRequest(`/store/carts/${cartId}/line-items`, updates);
  return cart;
};

export const updateLineItemInCart = async (cartId, itemId, updates) => {
  const { cart } = await postRequest(`/store/carts/${cartId}/line-items/${itemId}`, updates);
  return cart;
};

export const removeLineItemInCart = async (cartId, itemId) => await deleteRequest(`/store/carts/${cartId}/line-items/${itemId}`);

export const updateShippingMethod = async (cartId, method) => {
  const res = await getRequest(
    `/store/shipping-options?cart_id=${cartId}`,
    // `/store/carts/${cartId}?fields=+shipping_methods.id`
    // `/store/shipping-options`,
  );
  console.log('shipping_methods', res);

  const calculateDefault = await postRequest(`/store/shipping-options/${res?.shipping_options?.[0]?.id}/calculate`, {
    cart_id: cartId,
    data: {
      name: method,
    },
  });

  const calculateExpress = await postRequest(`/store/shipping-options/${res?.shipping_options?.[1]?.id}/calculate`, {
    cart_id: cartId,
    data: {
      name: method,
    },
  });
  console.log('calculateExpress', calculateExpress)
  const cart = await postRequest(`/store/carts/${cartId}/shipping-methods`, {
    option_id: calculateExpress?.shipping_option?.id,
    data: {
      name: method,
    },
  });

  return cart;
};

export const addCDEKOfficeToCart = async (cartId, params) => {
  const { cart } = await postRequest(`/store/delivery/select/${cartId}`, {
    office_code: params,
  });
  return cart;
};

export const getSelectedCDEKOffice = async (cartId) => {
  const { address } = await getRequest(`/store/delivery/select/${cartId}`);
  return address;
};
