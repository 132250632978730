import React, { useMemo, useState } from "react";
import { RoundedButton } from "../../shared/RoundedButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  removeLineItemInCart,
  updateLineItemInCart,
} from "../../shared/libs/requests/cart";
import { setCart } from "../../app/shopSlice";
import { useDispatch } from "react-redux";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { formatPrice } from "../../shared/libs/helpers";
import CloseIcon from "@mui/icons-material/Close";
import { setLocalItem } from "../../shared/libs/localStorage";

import "./index.scss";

export const MinimalisticProductCard = ({
  product,
  xs,
  isVariant,
  cart,
  ...props
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(product?.quantity);

  const onProductNavigate = () => {
    props.onCloseSearch?.();
    navigate(`/product/${product.id}`);
  };

  const selectedVariantPrice = useMemo(() => {
    if (product?.variants?.length) {
      return product.variants.find((el) => el.calculated_price); // Берём вариант у которого есть цена как дефолтный
    }
    return null;
  }, [product]);

  const price = selectedVariantPrice?.calculated_price?.calculated_amount
    ? formatPrice(selectedVariantPrice.calculated_price.calculated_amount)
    : "N/A";

  const variantPrice = product?.unit_price
    ? formatPrice(product?.unit_price)
    : null;

  const originalPrice = selectedVariantPrice?.calculated_price?.original_amount
    ? formatPrice(selectedVariantPrice.calculated_price.original_amount)
    : null;

  const getImgUrl = isVariant ? product?.thumbnail : product?.images?.[0]?.url;
  const getItemPrice = isVariant ? variantPrice : price;
  const getItemTitle = isVariant ? product?.product_title : product?.title;

  const updateQuantity = async (isIncrease) => {
    try {
      let quantityTemp = quantity;
      let updatedCart = {};
      if (isIncrease) quantityTemp += 1;
      if (quantity > 0 && !isIncrease) quantityTemp -= 1;

      if (quantityTemp === 0) {
        const { parent } = await removeLineItemInCart(cart?.id, product?.id);
        updatedCart = parent;
      } else {
        updatedCart = await updateLineItemInCart(cart?.id, product?.id, {
          quantity: quantityTemp,
        });
      }
      setQuantity(quantityTemp);
      setLocalItem("cart", updatedCart);
      dispatch(setCart(updatedCart));
    } catch (e) {
      console.error(e);
    }
  };

  const removeItemHandler = async () => {
    try {
      const { parent } = await removeLineItemInCart(cart?.id, product?.id);
      dispatch(setCart(parent));
      setLocalItem("cart", parent);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid item xs={xs} spacing={4} className="minimalistic-product-card">
      <div
        className="minimalistic-product-card__image-container"
        onClick={onProductNavigate}
      >
        <span style={{ backgroundImage: `url(${getImgUrl})` }} />
      </div>
      <div className="minimalistic-product-card__info">
        <p
          className="minimalistic-product-card__title"
          onClick={onProductNavigate}
        >
          {getItemTitle}
        </p>
        <div className="minimalistic-product-card__button-box">
          {quantity > 0 ? (
            <div className="minimalistic-product-card__quantity-controller">
              <ShoppingCartIcon />
              <RemoveRounded onClick={() => updateQuantity(false)} />
              <span className="minimalistic-product-card__quantity">
                {quantity}
              </span>
              <AddRounded onClick={() => updateQuantity(true)} />
            </div>
          ) : (
            <RoundedButton>
              <ShoppingCartIcon />
              <span>{getItemPrice}</span>
            </RoundedButton>
          )}
        </div>
      </div>
      <CloseIcon className="close-btn" onClick={removeItemHandler} />
    </Grid>
  );
};
