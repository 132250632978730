import { createSlice } from "@reduxjs/toolkit";

export const shopSlice = createSlice({
  name: "shop",
  initialState: {
    product: "",
    categories: [],
    cart: {},
    popularItems: [],
  },
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setPopularItems: (state, action) => {
      state.popularItems = action.payload;
    },
  },
});

export const { setProduct, setCategories, setCart, setPopularItems } =
  shopSlice.actions;
export default shopSlice.reducer;
