import React from "react";

import "./index.scss";

export const ProfileUserCardsSection = () => {
  return (
    <div className="profile-user-cards-container">
      <div className="user-card">
        <p>Скидка в 5%</p>
        <span>зарегистрированный пользователь</span>
      </div>
    </div>
  );
};
