import React, { useEffect, useState } from 'react';
import { Grid, Button, Box, Typography } from '@mui/material';
import { CatalogProductCard } from '../../features/CatalogProductCard';
import { CatalogProductFilter } from '../../features/CatalogProductFilter';
import { AddItemToCartPopup } from '../../features/AddItemToCartPopup';
import { CardSkeletonForm } from '../../features/CardSkeletonForm';
import { useProducts } from '../../shared/libs/requests/product';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartHandler } from '../../shared/libs/helpers';
import { filteredProductsState } from '../../app/constants';

import './index.scss';

export const CatalogForm = ({ catalogId }) => {
  const dispatch = useDispatch();
  const filteredProducts = useSelector((state) => state.shop.filteredProducts);

  const productLimit = 100;
  const {
    products: { products, count: totalCount },
    loading,
    error,
    refetch,
    loadMore,
  } = useProducts({
    category_id: catalogId,
    fields: `*variants.calculated_price`,
    country_code: 'RU',
    limit: productLimit,
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [addedProduct, setAddedProduct] = useState(null);
  const [cartId, setCartId] = useState(null);

  const actualProducts = filteredProducts.state === filteredProductsState.filtered ? filteredProducts?.products : products;
  console.log('filteredProducts', filteredProducts);
  const buttonUpHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (catalogId) refetch();
  }, [catalogId]);

  return (
    <div className="catalog-form main-container">
      <AddItemToCartPopup triggerSnackbar={showSnackbar} product={addedProduct} />
      <CatalogProductFilter />
      {actualProducts && actualProducts.length > 0 ? (
        <div className="padding-container">
          <Grid container spacing={2}>
            {actualProducts.map((product) => (
              <CatalogProductCard
                key={product.id}
                product={product}
                showUpButton={true}
                addToCartHandler={(productData) =>
                  addToCartHandler(productData, cartId, setCartId, setShowSnackbar, setAddedProduct, dispatch)
                }
                xs={3}
              />
            ))}
          </Grid>
        </div>
      ) : !loading && !actualProducts.length ? (
        <Typography className="empty-product-list">
          Мы ничего не нашли. <br />
          Попробуйте изменить запрос и мы поищем ещё раз.
        </Typography>
      ) : (
        <CardSkeletonForm cardsCount={16} />
      )}
      {loading && <CardSkeletonForm cardsCount={16} />}
      {!loading && actualProducts?.length < totalCount && filteredProducts.state === filteredProductsState.notFiltered && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            variant="contained"
            onClick={() => loadMore(actualProducts?.length, productLimit)}
            endIcon={<span style={{ transform: 'rotate(90deg)' }}>➔</span>}
          >
            Показать еще
          </Button>
        </Box>
      )}
      <Button
        variant="contained"
        onClick={buttonUpHandler}
        className="catalog-product-card__button-up"
        startIcon={<span style={{ transform: 'rotate(-90deg)' }}>➔</span>}
      >
        Наверх
      </Button>
    </div>
  );
};
