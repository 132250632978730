import React from "react";
import { RoundedButton } from "../../shared/RoundedButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./index.scss";

export const MinimalisticProductCard = ({ product, xs, ...props }) => {
  const navigate = useNavigate();

  const onProductNavigate = () => {
    props.onCloseSearch();
    navigate(`/product/${product.id}`);
  };

  return (
    <Grid item xs={xs} spacing={4} className="minimalistic-product-card">
      <div
        className="minimalistic-product-card__image-container"
        onClick={onProductNavigate}
      >
        <span
          style={{ backgroundImage: `url(${product?.images?.[0]?.url})` }}
        />
      </div>
      <div className="minimalistic-product-card__info">
        <p
          className="minimalistic-product-card__title"
          onClick={onProductNavigate}
        >
          {product?.title}
        </p>
        <div className="minimalistic-product-card__button-box">
          <RoundedButton>
            <ShoppingCartIcon/>
            <li key={product?.variants?.[0].id}>
              {(product?.variants?.[0]?.prices[0]?.amount / 100).toFixed(0)} ₽
            </li>
            {/*{product.variants.map((variant) => (*/}
            {/*  <li key={variant.id}>*/}
            {/*    {(variant?.prices[0]?.amount / 100).toFixed(0)} ₽*/}
            {/*  </li>*/}
            {/*))}*/}
          </RoundedButton>
        </div>
      </div>
    </Grid>
  );
};
