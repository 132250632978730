import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { RoundedButton } from "../../shared/RoundedButton";
import { ThankYouModalForm } from "../ThankYouModalForm";
import { useSelector, useDispatch } from "react-redux";
import { createOrder } from "../../shared/libs/requests/order";
import { formatPrice } from "../../shared/libs/helpers";
import { setShowAddressForm } from "../../app/userSlice";
import {
  updateCart,
  updateShippingMethod,
} from "../../shared/libs/requests/cart";
import { setLocalItem } from "../../shared/libs/localStorage";
import { setCart } from "../../app/shopSlice";

import "./index.scss";

const paymentMethods = [
  { value: "fastPayment", label: "Система быстрых платежей (СБП)" },
  { value: "bankCard", label: "Банковская карта" },
  { value: "onDelivery", label: "Оплата при получении" },
  { value: "bankTransfer", label: "Перевод по реквизитам (для ООО, ИП)" },
];

const deliveryMethods = [
  { value: "sdek-pvz", label: "СДЭК (пункт выдачи)", method: "CDEK" },
  { value: "yandex-pvz", label: "Яндекс (пункт выдачи)", method: "Yandex.GO" },
];

export const PlacingOrderForm = () => {
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user.user);
  const cartStore = useSelector((state) => state.shop.cart);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(
    userStore?.addresses?.find((el) => el.is_default_shipping) || null,
  );
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    deliveryMethods[0].value,
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentMethods[0].value,
  );

  const [orderSummary, setOrderSummary] = useState({
    productsCost: 0,
    totalCost: 0,
    discount: 0,
    shippingCost: 0,
  });

  useEffect(() => {
    // Подсчет стоимости товаров
    const productsCost = cartStore?.items?.reduce(
      (total, item) => total + item.unit_price * item.quantity,
      0,
    );

    // Пример подсчета скидки (5% для зарегистрированных пользователей)
    const discount = userStore?.id ? productsCost * 0.05 : 0;

    setOrderSummary({
      productsCost,
      discount,
      shippingCost: 0, // Добавьте логику расчета стоимости доставки, если потребуется
      totalCost: productsCost - discount,
    });
  }, [cartStore, userStore]);

  const setShippingMethod = async (methodName) => {
    try {
      setIsLoading(true);
      const shippingMethod =
        deliveryMethods.find((el) => el.value === methodName) || "";
      const { cart } = await updateShippingMethod(
        cartStore?.id,
        shippingMethod?.method,
      );
      dispatch(setCart(cart));
      setLocalItem("cart", cart);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddressChange = async (addressId) => {
    const address = userStore.addresses.find((el) => el.id === addressId);
    if (!address) {
      console.error("Адрес не найден");
      return;
    }

    setSelectedAddress(address);
    try {
      const cart = await updateCart(cartStore.id, { shipping_address: { address_1: address?.address_1 }});
    } catch (error) {
      console.error("Ошибка при обновлении корзины:", error);
    }
  };

  const handleAddAddress = () => dispatch(setShowAddressForm("new"));

  const handleDeliveryChange = async (event) => {
    setSelectedDeliveryMethod(event.target.value);
    await setShippingMethod(event.target.value);
  };

  const handlePaymentChange = (event) =>
    setSelectedPaymentMethod(event.target.value);

  const onPayHandler = () => {
    // Создание заказа через Medusa.js
    createOrder({
      customer_id: userStore.id,
      shipping_address: selectedAddress,
      items: cartStore.items.map((item) => ({
        variant_id: item.variant_id,
        quantity: item.quantity,
      })),
      total: orderSummary.totalCost,
    })
      .then(() => {
        setShowSuccessModal(true);
      })
      .catch((err) => console.error("Error creating order:", err));
  };

  const getSelectedDeliveryLabel = () =>
    selectedDeliveryMethod
      ? deliveryMethods.find((el) => el.value === selectedDeliveryMethod)?.label
      : "Адрес не указан";

  const getSelectedPaymentLabel = () =>
    selectedPaymentMethod
      ? paymentMethods.find((el) => el.value === selectedPaymentMethod)?.label
      : "Способ оплаты не указан";

  const isPaymentBtnDisabled = () => !cartStore?.shipping_total || isLoading;

  useEffect(() => {
    if (userStore?.addresses) {
      setSelectedAddress(
        userStore?.addresses?.find((el) => el.is_default_shipping),
      );
    }
  }, [userStore?.addresses]);

  useEffect(() => {
    if (cartStore?.id && selectedDeliveryMethod) {
      setIsLoading(true);
      setShippingMethod(selectedDeliveryMethod).then((res) => {
        setIsLoading(false);
      });
    }
  }, [cartStore?.id, selectedDeliveryMethod]);

  return (
    <div className="placing-order-form main-container">
      <Typography className="placing-order-title">
        Адрес и способ доставки
      </Typography>

      <ThankYouModalForm
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />

      <div className="placing-container">
        <div className="placing-column">
          <div className="placing-box">
            <Typography className="title">Населенный пункт</Typography>
            {userStore?.addresses?.length ? (
              <RadioGroup
                aria-labelledby="location-radio-group-label"
                value={selectedAddress?.id}
                name="location-radio-group"
                onChange={(e) => handleAddressChange(e.target.value)}
              >
                {userStore.addresses.map((el) => (
                  <FormControlLabel
                    key={el.id}
                    value={el.id}
                    className="radio-btn"
                    control={<Radio />}
                    label={el.address_1}
                  />
                ))}
              </RadioGroup>
            ) : null}
            <div className="add-address-container">
              <RoundedButton onClick={handleAddAddress}>
                + Добавить адрес
              </RoundedButton>
            </div>
          </div>

          <div className="placing-box">
            <Typography className="title">Способ доставки</Typography>
            <RadioGroup
              aria-labelledby="delivery-method-radio-group-label"
              defaultValue={selectedDeliveryMethod}
              name="delivery-method-radio-group"
              onChange={handleDeliveryChange}
            >
              {(deliveryMethods || []).map((el) => (
                <FormControlLabel
                  value={el.value}
                  control={<Radio />}
                  label={el.label}
                />
              ))}
            </RadioGroup>
          </div>

          <div className="placing-user-info-box">
            <Typography className="title">Получатель</Typography>
            <div className="info-row">
              <span>Имя</span>
              <span>{userStore?.first_name || "Не указано"}</span>
            </div>
            <div className="info-row">
              <span>Телефон</span>
              <span>{userStore?.phone || "Не указано"}</span>
            </div>
            <div className="info-row">
              <span>Почта</span>
              <span>{userStore?.email || "Не указано"}</span>
            </div>
          </div>

          <div className="placing-box">
            <Typography className="title">Способ оплаты</Typography>
            <RadioGroup
              aria-labelledby="payment-method-radio-group-label"
              defaultValue={selectedPaymentMethod}
              name="payment-method-radio-group"
              onChange={handlePaymentChange}
            >
              {(paymentMethods || []).map((el) => (
                <FormControlLabel
                  value={el.value}
                  control={<Radio />}
                  label={el.label}
                />
              ))}
            </RadioGroup>
          </div>
        </div>

        <div className="placing-column">
          <div className="placing-total-box">
            <Typography className="title">Сумма заказа</Typography>
            {/*<TextField*/}
            {/*  id="outlined-basic"*/}
            {/*  label="Введите промокод"*/}
            {/*  variant="outlined"*/}
            {/*  className="promocode"*/}
            {/*  size="small"*/}
            {/*/>*/}
            <div className="result-info">
              <div className="info-row">
                <span>Стоимость продуктов</span>
                <span>{formatPrice(orderSummary.productsCost)}</span>
              </div>
              <div className="info-row">
                <span>Скидка</span>
                <span className="discount">
                  -{formatPrice(orderSummary.discount)}
                </span>
              </div>
              <div className="info-row">
                <span>{getSelectedDeliveryLabel()}</span>
                <span>{formatPrice(cartStore?.shipping_total)}</span>
              </div>
              <div className="info-row">
                <span>Оплата</span>
                <span>{getSelectedPaymentLabel()}</span>
              </div>
              <div className="info-row">
                <span>Итого</span>
                <span className="total">{formatPrice(cartStore?.total)}</span>
              </div>
            </div>
          </div>
          <RoundedButton
            className="payment-btn"
            onClick={onPayHandler}
            disabled={isPaymentBtnDisabled()}
          >
            Оплатить заказ
          </RoundedButton>
        </div>
      </div>
    </div>
  );
};
