import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Paper, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addToCartHandler, formatPrice } from '../../shared/libs/helpers';
import { getProductById } from '../../shared/libs/requests/product';
import { RoundedButton } from '../../shared/RoundedButton';
import { AddItemToCartPopup } from '../AddItemToCartPopup';

import './index.scss';

export const SelectedProductForm = () => {
  const { prodId } = useParams();
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(null); // null to indicate loading
  const [value, setValue] = useState(0);
  const [cartId, setCartId] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [addedProduct, setAddedProduct] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const metadataFields = [
    { key: 'productType', label: 'Тип продукта' },
    { key: 'gender', label: 'Для кого' },
    { key: 'origin_country', label: 'Страна производства' },
    { key: 'prescription', label: 'Назначение' },
    { key: 'count', label: 'Количество' },
    { key: 'age', label: 'Возраст' },
  ];

  const price = (variant) =>
    variant?.calculated_price?.calculated_amount ? formatPrice(variant.calculated_price.calculated_amount) : 'N/A';

  const getTableValue = (key) => {
    if (key === 'origin_country') return selectedProduct?.origin_country;
    if (key === 'productType') return selectedProduct?.type?.value;
    if (key === 'count') return selectedProduct?.variants[0]?.weight;

    return selectedProduct?.metadata[key] || '-';
  };

  useEffect(() => {
    const fetchProduct = async () => {
      const { product } = await getProductById(prodId);
      setSelectedProduct(product);
    };
    fetchProduct();
  }, [prodId]);

  return (
    <div className="main-container">
      <div className="product-container">
        <AddItemToCartPopup triggerSnackbar={showSnackbar} product={addedProduct} />
        {selectedProduct ? (
          <img
            className="product-container__img"
            src={selectedProduct.images?.[0]?.url || 'https://via.placeholder.com/150'}
            alt={selectedProduct.title || 'Product Image'}
          />
        ) : (
          <Skeleton variant="rectangular" width={600} height={600} sx={{ borderRadius: '30px' }} />
        )}
        <div className="product-details">
          {/* <p className="product-details__description">
            {selectedProduct?.description || <Skeleton width="80%" />}
          </p> */}
          <p className="product-details__title">{selectedProduct?.title || <Skeleton width={400} height={100} />}</p>
          {selectedProduct?.variants ? (
            <div className="product-details__volume-box">
              <div className="volume-container">
                {selectedProduct.variants.map((variant) => (
                  <span key={variant.id}>{variant.title}</span>
                ))}
              </div>
              <p>
                <b>{selectedProduct?.metadata?.gender}</b>
              </p>
            </div>
          ) : null}
          <span className="product-details__price">
            {selectedProduct?.variants ? (
              selectedProduct.variants.map((variant) => <li key={variant.id}>{price(variant)}</li>)
            ) : (
              <Skeleton width={300} height={60} />
            )}
          </span>
          <RoundedButton
            className="product-details__cart"
            disabled={!selectedProduct}
            onClick={() => addToCartHandler(selectedProduct, cartId, setCartId, setShowSnackbar, setAddedProduct, dispatch)}
          >
            <ShoppingBasketIcon />
            Добавить в корзину
          </RoundedButton>
        </div>
      </div>
      <div className="product-tab-info">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="product details tabs">
            <Tab label="Описание" />
            {/* <Tab label="Бренд" /> */}
            {/* <Tab label="Способ применения" /> */}
            {/* <Tab label="Состав" /> */}
          </Tabs>
        </Box>
        <div style={{ minHeight: '600px', margin: '30px 0' }}>
          {value === 0 && (
            <>
              <Typography className="item-tab__title">{selectedProduct?.title}</Typography>
              <Typography className="item-tab__description">{selectedProduct?.description}</Typography>
              <Typography variant="p" fontWeight="600">
                Подробные характеристики
              </Typography>
              <TableContainer component={Paper} className="description-table" sx={{ marginTop: '20px', maxWidth: 800 }}>
                <Table sx={{ minWidth: 650 }} aria-label="product details table">
                  <TableBody>
                    {metadataFields.map(({ key, label }) => (
                      <TableRow
                        key={key}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {label}
                        </TableCell>
                        <TableCell align="right">{getTableValue(key)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {value === 1 && (
            <Typography variant="p" fontWeight="600">
              {selectedProduct?.metadata?.brand || 'N/A'}
            </Typography>
          )}
          {value === 2 && (
            <Typography variant="p" fontWeight="600">
              {selectedProduct?.metadata?.notes?.base || 'N/A'}
            </Typography>
          )}
          {value === 3 && (
            <Typography variant="p" fontWeight="600">
              {selectedProduct?.metadata?.notes?.upper || 'N/A'}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
