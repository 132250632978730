// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-drawer .MuiDrawer-paperAnchorRight {
  width: 100%;
}

.cart-drawer-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 24px;
}
.cart-drawer-container .common-button {
  flex-shrink: 0;
}

.cart-title {
  font-size: 30px !important;
  font-weight: 600 !important;
  line-height: 37px !important;
  border-bottom: 1px solid;
  padding-bottom: 8px;
}

.cart__show-more {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  text-align: center;
}
.cart__show-more:hover {
  cursor: pointer;
}

.card-total-cost .cost-container {
  display: flex;
  justify-content: space-between;
}
.card-total-cost .order-btn {
  margin-top: 24px;
  width: 100%;
  background: black;
  color: white !important;
  text-transform: math-auto;
  height: 42px;
}
.card-total-cost .order-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

.MuiInputBase-input::placeholder {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/features/CartModalForm/index.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AADF;AAGE;EACE,cAAA;AADJ;;AAKA;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;EACA,wBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,yBAAA;EACA,0BAAA;EACA,2BAAA;EACA,4BAAA;EACA,kBAAA;AAFF;AAIE;EACE,eAAA;AAFJ;;AAOE;EACE,aAAA;EACA,8BAAA;AAJJ;AAME;EACE,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,uBAAA;EACA,yBAAA;EACA,YAAA;AAJJ;AAMI;EACE,8BAAA;AAJN;;AASA;EACE,eAAA;AANF","sourcesContent":[".cart-drawer {\n  & .MuiDrawer-paperAnchorRight {\n    width: 100%;\n  }\n}\n\n.cart-drawer-container {\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n  padding: 24px;\n\n  & .common-button {\n    flex-shrink: 0;\n  }\n}\n\n.cart-title {\n  font-size: 30px !important;\n  font-weight: 600 !important;\n  line-height: 37px !important;\n  border-bottom: 1px solid;\n  padding-bottom: 8px;\n}\n\n.cart__show-more {\n  color: rgba(0, 0, 0, 0.5);\n  font-size: 14px !important;\n  font-weight: 700 !important;\n  line-height: 17px !important;\n  text-align: center;\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.card-total-cost {\n  & .cost-container {\n    display: flex;\n    justify-content: space-between;\n  }\n  & .order-btn {\n    margin-top: 24px;\n    width: 100%;\n    background: black;\n    color: white !important;\n    text-transform: math-auto;\n    height: 42px;\n\n    &:hover {\n      background: rgba(0, 0, 0, 0.8);\n    }\n  }\n}\n\n.MuiInputBase-input::placeholder {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
