import React, { useState } from "react";
import { Grid } from "@mui/material";
import { CatalogProductCard } from "../../features/CatalogProductCard";
import { CatalogProductFilter } from "../../features/CatalogProductFilter";
import { useProducts } from "medusa-react";
import { AddItemToCartPopup } from "../../features/AddItemToCartPopup";
import { CardSkeletonForm } from "../../features/CardSkeletonForm";

import "./index.scss";

export const CatalogForm = () => {
  const { products, isLoading } = useProducts();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [addedProduct, setAddedProduct] = useState(null);

  const addToCartHandler = async (productData) => {
    setAddedProduct(productData);
    setShowSnackbar(true);
    setTimeout(() => setShowSnackbar(false), 3000); // время отображения снекбара
  };

  return (
    <div className="catalog-form main-container">
      <AddItemToCartPopup
        triggerSnackbar={showSnackbar}
        product={addedProduct}
      />
      <CatalogProductFilter defaultValues={[0, 1200]} />
      {isLoading ? (
        <CardSkeletonForm cardsCount={16} />
      ) : products && products.length > 0 ? (
        <Grid container spacing={2}>
          {products.map((product) => (
            <CatalogProductCard
              key={product.id}
              product={product}
              addToCartHandler={addToCartHandler}
              xs={3}
            />
          ))}
        </Grid>
      ) : (
        <span>No Products</span>
      )}
    </div>
  );
};
