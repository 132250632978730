import { createSlice } from '@reduxjs/toolkit';

export const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    product: '',
    categories: [],
    cart: {},
    popularItems: [],
    itemsAttributes: {},
    filteredProducts: { state: 'not-filtered', products: [] },
  },
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setFilteredProducts: (state, action) => {
      state.filteredProducts = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setPopularItems: (state, action) => {
      state.popularItems = action.payload;
    },
    setItemsAttributes: (state, action) => {
      state.itemsAttributes = action.payload;
    },
  },
});

export const { setProduct, setCategories, setCart, setPopularItems, setItemsAttributes, setFilteredProducts } = shopSlice.actions;
export default shopSlice.reducer;
