import React from "react";
import { Button } from "@mui/material";

import "./index.scss";

export const RoundedButton = ({ className, children, onClick, disabled }) => {
  return (
    <Button
      component="label"
      variant="text"
      className={`icon-button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
