import { Grid, Skeleton } from "@mui/material";
import React from "react";

import './index.scss';

export const CardSkeleton = ({ isMinimalistic, xs }) => (
  <Grid item xs={xs} className={`skeleton ${isMinimalistic ? 'minimalistic' : ''}`}>
    <Skeleton className="img" variant="rectangular" />
    <div>
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="80%" />
    </div>
  </Grid>
);
