import React, { useState, useEffect } from "react";
import Medusa from "@medusajs/medusa-js";
import { Card, Button, Typography, CircularProgress } from "@mui/material";
import cardImage from "../../media/card-image.png";
import { RoundedButton } from "../../shared/RoundedButton";

import "./index.scss";

const medusa = new Medusa({
  baseUrl: process.env.REACT_APP_MEDUSA_BACKEND_URL,
  maxRetries: 3,
});

export const ProfileUserOrdersSection = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await medusa.orders.list();
        setOrders(response.orders);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
  };

  const handleBackClick = () => {
    setSelectedOrder(null);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className="profile-user-order-container">
      {!selectedOrder ? (
        <div className="order-list">
          {/*{orders.length === 0 ? (*/}
          {/*  <Typography>Заказов пока нет</Typography>*/}
          {/*) : (*/}
          {/*  orders.map((order) => (*/}
          <Card
            // key={order.id}
            onClick={() => handleOrderClick("order")}
            className="order-card"
          >
            <div className="order-card__box">
              <Typography variant="body2" className="order-date">
                12 окт.
              </Typography>
              <div className="order-status">
                <span className="order-type online">Онлайн-покупка</span>
                <span className="order-id">№ 2578496348</span>
              </div>
            </div>

            <div className="order-card__box">
              <Typography className="order-title">Состав / 4 шт.</Typography>
              <div className="order-icons-container">
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
              </div>
            </div>

            <div className="order-card__box">
              <Typography className="order-title">Сумма</Typography>
              <Typography className="order-total">106 249 ₽</Typography>
              <Typography className="order-title">
                Подробнее о заказе →
              </Typography>
            </div>
          </Card>
          <Card
            // key={order.id}
            onClick={() => handleOrderClick("order")}
            className="order-card"
          >
            <div className="order-card__box">
              <Typography variant="body2" className="order-date">
                12 окт.
              </Typography>
              <div className="order-status">
                <span className="order-type online">Онлайн-покупка</span>
                <span className="order-id">№ 2578496348</span>
              </div>
            </div>

            <div className="order-card__box">
              <Typography className="order-title">Состав / 4 шт.</Typography>
              <div className="order-icons-container">
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
              </div>
            </div>

            <div className="order-card__box">
              <Typography className="order-title">Сумма</Typography>
              <Typography className="order-total">106 249 ₽</Typography>
              <Typography className="order-title">
                Подробнее о заказе →
              </Typography>
            </div>
          </Card>
          <Card
            // key={order.id}
            onClick={() => handleOrderClick("order")}
            className="order-card"
          >
            <div className="order-card__box">
              <Typography variant="body2" className="order-date">
                12 окт.
              </Typography>
              <div className="order-status">
                <span className="order-type online">Онлайн-покупка</span>
                <span className="order-id">№ 2578496348</span>
              </div>
            </div>

            <div className="order-card__box">
              <Typography className="order-title">Состав / 4 шт.</Typography>
              <div className="order-icons-container">
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
              </div>
            </div>

            <div className="order-card__box">
              <Typography className="order-title">Сумма</Typography>
              <Typography className="order-total">106 249 ₽</Typography>
              <Typography className="order-title">
                Подробнее о заказе →
              </Typography>
            </div>
          </Card>
          <Card
            // key={order.id}
            onClick={() => handleOrderClick("order")}
            className="order-card"
          >
            <div className="order-card__box">
              <Typography variant="body2" className="order-date">
                12 окт.
              </Typography>
              <div className="order-status">
                <span className="order-type online">Онлайн-покупка</span>
                <span className="order-id">№ 2578496348</span>
              </div>
            </div>

            <div className="order-card__box">
              <Typography className="order-title">Состав / 4 шт.</Typography>
              <div className="order-icons-container">
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
                <img className="order-icon" src={cardImage} alt="" />
              </div>
            </div>

            <div className="order-card__box">
              <Typography className="order-title">Сумма</Typography>
              <Typography className="order-total">106 249 ₽</Typography>
              <Typography className="order-title">
                Подробнее о заказе →
              </Typography>
            </div>
          </Card>
          {/*))*/}
          {/*)}*/}
        </div>
      ) : (
        <div className="order-details">
          <Button className="return-button" onClick={handleBackClick}>
            ← Мои покупки
          </Button>
          <div className="order-container">
            <div className="order-status">
              <span className="order-id">№ 2578496348</span>
              <span className="order-type online">Онлайн-покупка</span>
            </div>

            <div className="order-info-container">
              <div className="order-info-order">
                <Typography className="order-info-title">Информация о заказе</Typography>
                <div className="info-row">
                  <span>Дата покупки</span>
                  <span>12 октября, 20:55</span>
                </div>
                <div className="info-row">
                  <span>Способ оплаты</span>
                  <span>Картой</span>
                </div>
                <div className="info-row">
                  <span>Адрес магазина</span>
                  <span>Онлайн-магазин</span>
                </div>
              </div>

              <div className="order-info-order">
                <Typography className="order-info-title">Сумма заказа</Typography>
                <div className="info-row">
                  <span>Продукты</span>
                  <span>100 249 ₽</span>
                </div>
                <div className="info-row">
                  <span>Скидка</span>
                  <span className="order-discount">-20 000 ₽</span>
                </div>
                <div className="info-row without-border">
                  <span>Итого</span>
                  <span className="order-total">100 249 ₽</span>
                </div>
              </div>
            </div>

            <div>
              <Typography className="order-info-title">Состав заказа / 2 шт.</Typography>
            </div>
          </div>
          <RoundedButton className="repeat-button">Повторить заказ</RoundedButton>
        </div>
      )}
    </div>
  );
};

export default ProfileUserOrdersSection;
