import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const PromptModalWindow = ({ open, handleClose, handleLogout }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
      <DialogTitle id="logout-dialog-title">Выход из аккаунта</DialogTitle>
      <DialogContent>
        <DialogContentText id="logout-dialog-description">
          Вы уверены, что хотите выйти из аккаунта?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Отмена
        </Button>
        <Button onClick={handleLogout} color="primary" autoFocus>
          Выйти
        </Button>
      </DialogActions>
    </Dialog>
  );
};