import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { RoundedButton } from "../../shared/RoundedButton";

import "./index.scss";
import { ThankYouModalForm } from "../ThankYouModalForm";

export const PlacingOrderForm = () => {
  let { orderListId } = useParams();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const onPayHandler = () => {
    //TODO: Добавить переход на оплату заказа, а оттуда на модалку "спасибо"
    setShowSuccessModal(true);
  };

  return (
    <div className="placing-order-form main-container">
      <Typography className="placing-order-title">
        Адрес и способ доставки
      </Typography>

      <ThankYouModalForm
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />

      <div className="placing-container">
        <div className="placing-column">
          <div className="placing-box">
            <Typography className="title">населенный пункт</Typography>
            <RadioGroup
              aria-labelledby="location-radio-group-label"
              defaultValue="moscow"
              name="location-radio-group"
            >
              <FormControlLabel
                value="moscow"
                className="radio-btn"
                control={<Radio />}
                label="г. Москва, ул. Московская, д. 6, кв. 750"
              />
              <FormControlLabel
                value="bottomNovgorod"
                className="radio-btn"
                control={<Radio />}
                label="г. Нижний Новгород, ул. Московская, д. 6, кв. 750"
              />
              <FormControlLabel
                value="spb"
                className="radio-btn"
                control={<Radio />}
                label="г. Санкт-Петербург, ул. Московская, д. 6, кв. 750"
              />
            </RadioGroup>
            <div>
              <RoundedButton>+ Добавить адрес</RoundedButton>
            </div>
          </div>

          <div className="placing-box">
            <Typography className="title">способ доставки</Typography>
            <RadioGroup
              aria-labelledby="delivery-method-radio-group-label"
              defaultValue="mailRussia-pvz"
              name="delivery-method-radio-group"
            >
              <FormControlLabel
                value="mailRussia-pvz"
                control={<Radio />}
                label="Почта России (пункт выдачи)"
              />
              <FormControlLabel
                value="mailRussia-deliverMan"
                control={<Radio />}
                label="Почта России (курьер)"
              />
              <FormControlLabel
                value="sdek-pvz"
                control={<Radio />}
                label="СДЭК (пункт выдачи)"
              />
              <FormControlLabel
                value="sdek-deliverMan"
                control={<Radio />}
                label="СДЭК (курьер)"
              />
            </RadioGroup>
          </div>

          <div className="placing-user-info-box">
            <Typography className="title">Получатель</Typography>
            <div className="info-row">
              <span>Имя</span>
              <span>Артем</span>
            </div>
            <div className="info-row">
              <span>Телефон</span>
              <span>+7 999 999 99 99</span>
            </div>
            <div className="info-row">
              <span>Почта</span>
              <span>info@mailbox.com</span>
            </div>
          </div>

          <div className="placing-box">
            <Typography className="title">способ оплаты</Typography>
            <RadioGroup
              aria-labelledby="payment-method-radio-group-label"
              defaultValue="fastPayment"
              name="payment-method-radio-group"
            >
              <FormControlLabel
                value="fastPayment"
                control={<Radio />}
                label="Система быстрых платежей (СБП)"
              />
              <FormControlLabel
                value="bankCard"
                control={<Radio />}
                label="Банковская карта"
              />
              <FormControlLabel
                value="onDelivery"
                control={<Radio />}
                label="Оплата при получении"
              />
              <FormControlLabel
                value="bankTransfer"
                control={<Radio />}
                label="Перевод по реквизитам (для ООО, ИП)"
              />
            </RadioGroup>
          </div>
        </div>

        <div className="placing-column">
          <div className="placing-total-box">
            <Typography className="title">Сумма заказа</Typography>
            <TextField
              id="outlined-basic"
              label="Введите промокод"
              variant="outlined"
              className="promocode"
              size="small"
            />
            <div className="result-info">
              <div className="info-row">
                <span>Стоимость продуктов</span>
                <span>100 249 ₽</span>
              </div>
              <div className="info-row">
                <span>Скидка</span>
                <span className="discount">-50 249 ₽</span>
              </div>
              <div className="info-row">
                <span>Доставка</span>
                <span>ПОЧТА РОССИИ (ПУНКТ ВЫДАЧИ)</span>
              </div>
              <div className="info-row">
                <span>Итого</span>
                <span className="total">100 249 ₽</span>
              </div>
            </div>
          </div>
          <RoundedButton className="payment-btn" onClick={onPayHandler}>
            Оплатить заказ
          </RoundedButton>
        </div>
      </div>
    </div>
  );
};
