import React from "react";
import { Grid } from "@mui/material";
import { CatalogProductCard } from "../CatalogProductCard";
import { useSelector } from "react-redux";
import { useProducts } from "../../shared/libs/requests/product";
import { CardSkeletonForm } from "../CardSkeletonForm";
import Typography from "@mui/material/Typography";

import "./index.scss";

export const ProfileUserFavoriteSection = () => {
  const userStore = useSelector((state) => state.user.user);
  const {
    products: { products },
    loading,
    error,
  } = useProducts({
    id: userStore?.metadata?.favoriteItems,
    fields: `*variants.calculated_price`,
    country_code: "RU",
  });

  const addToCartHandler = () => {};

  return (
    <div className="profile-user-favorite-container">
      {loading ? (
        <CardSkeletonForm cardsCount={8} />
      ) : products && products.length > 0 ? (
        <Grid container spacing={4}>
          {products.map((product) => (
            <CatalogProductCard
              key={product.id}
              product={product}
              addToCartHandler={addToCartHandler}
              xs={3}
            />
          ))}
        </Grid>
      ) : (
        <Typography>Товаров пока нет</Typography>
      )}
    </div>
  );
};
