import React, { useState } from "react";

import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import InputMask from "react-input-mask";
import { RoundedButton } from "../../shared/RoundedButton";
import { PromptModalWindow } from "../../widgets/PromptModalWindow";
import { setUser } from "../../app/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLocalItem, removeLocalItem } from "../../shared/libs/localStorage";
import { updateCustomer } from "../../shared/libs/requests/user";
import { saveUserInfo } from "../../shared/libs/helpers";
import { tempRetrieveAuthToken } from "../../shared/libs/requests/auth";

import "./index.scss";

export const ProfileUserInfoSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.user.user);

  const [formData, setFormData] = useState({
    name: userStore?.first_name || "",
    surname: userStore?.last_name || "",
    male: userStore?.metadata?.gender || "male",
    birthday: userStore?.metadata?.birthday || null,
    phone: userStore?.phone || "",
    email: userStore?.email || userStore?.metadata?.email || "",
  });

  const [errors, setErrors] = useState({});
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Необходимо ввести ваше имя";
    }

    if (
      formData.phone.trim() &&
      !/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(formData.phone)
    ) {
      newErrors.phone = "Неверный формат телефона";
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Неверный формат почты";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async () => {
    if (validateForm()) {
      try {
        const customer = await updateCustomer({
          first_name: formData.name,
          last_name: formData.surname,
          phone: formData.phone,
          metadata: {
            gender: formData.male,
            birthday: formData.birthday,
            email: formData.email,
          },
        });
        saveUserInfo(customer, dispatch);
      } catch (error) {
        console.error("Failed to save user information", error);
      }
    }
  };

  const handleChange = (field) => (event) => {
    setFormData((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const logoutModalHandler = async () => {
    try {
      const token = await tempRetrieveAuthToken();
      fetch(`${process.env.REACT_APP_MEDUSA_BACKEND_URL}/auth/session`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token || getLocalItem("token")}`,
          "Content-Type": "application/json",
          "x-publishable-api-key": process.env.REACT_APP_PUBLISH_API_KEY,
          credentials: "include",
        },
      })
        .then((res) => res.json())
        .then(() => {
          dispatch(setUser(null));
          navigate("/");
          removeLocalItem("token");
          removeLocalItem("user");
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="profile-user-info-container">
      <h3 className="tab-title">Личная информация</h3>
      <TextField
        id="field-name"
        label="Имя"
        variant="standard"
        value={formData.name}
        onChange={handleChange("name")}
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField
        id="field-surname"
        label="Фамилия"
        variant="standard"
        value={formData.surname}
        onChange={handleChange("surname")}
        error={!!errors.surname}
        helperText={errors.surname}
      />

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="male"
        name="radio-buttons-group"
        value={formData.male}
        onChange={handleChange("male")}
      >
        <FormControlLabel value="male" control={<Radio />} label="Мужчина" />
        <FormControlLabel value="female" control={<Radio />} label="Женщина" />
      </RadioGroup>
      {errors.male && <div className="error-text">{errors.male}</div>}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField
          label="Дата рождения"
          disableFuture
          variant="standard"
          value={formData.birthday}
          onChange={(value) =>
            setFormData((prev) => ({ ...prev, birthday: value }))
          }
          error={!!errors.birthday}
          helperText={errors.birthday}
        />
      </LocalizationProvider>

      <InputMask
        mask="+7 (999) 999-99-99"
        value={formData.phone}
        onChange={handleChange("phone")}
      >
        {(inputProps) => (
          <TextField
            {...inputProps}
            label="Телефон"
            variant="standard"
            error={!!errors.phone}
            helperText={errors.phone}
          />
        )}
      </InputMask>
      <TextField
        id="field-mail"
        label="Почта"
        type="email"
        required
        variant="standard"
        value={formData.email}
        onChange={handleChange("email")}
        error={!!errors.email}
        helperText={errors.email}
      />
      <div className="user-info__action-container">
        <RoundedButton
          onClick={onSubmitHandler}
          className="user-info__save-btn"
        >
          Сохранить
        </RoundedButton>
        <RoundedButton
          className="user-info__exit-btn"
          onClick={() => setShowLogoutModal(true)}
        >
          Выйти из аккаунта
        </RoundedButton>
        <PromptModalWindow
          open={showLogoutModal}
          handleClose={() => setShowLogoutModal(false)}
          handleLogout={logoutModalHandler}
        />
      </div>
    </div>
  );
};
