import { useEffect, useState, useCallback } from "react";
import { getRequest } from "./index";

export const useProducts = (params) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [trigger, setTrigger] = useState(0); // Для перезапуска useEffect

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    try {
      // const response = await getRequest("/store/filter?limit=0&offset=0&name=text", {
      const response = await getRequest("/store/products", {
        fields: `*variants.calculated_price`,
        country_code: "RU",
        ...params,
      });
      setProducts(response);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    fetchProducts();
  }, [trigger]);

  const refetch = () => setTrigger((prev) => prev + 1);

  const loadMore = async (offset, limit) => {
    try {
      const response = await getRequest("/store/products", {
        fields: `*variants.calculated_price`,
        country_code: "RU",
        offset,
        limit,
        ...params,
      });
      setProducts((prev) => ({
        ...prev,
        products: [...prev?.products, ...response?.products],
      }));
    } catch (err) {
      setError(err);
    }
  };

  return { products, loading, error, refetch, loadMore };
};

export const getFilteredProduct = async (params) => {
  const { products } = await getRequest("/store/products", {
    fields: `*variants.calculated_price`,
    country_code: "RU",
    ...params,
  });

  return products;
};

export const fetchCategories = async () => {
  const { product_categories } = await getRequest("/store/product-categories");
  return product_categories?.map((category) => ({
    id: category.id,
    title: category.name,
    detailList: category.subcategories.map((sub) => ({
      id: sub.id,
      title: sub.name,
      isActive: false,
    })),
  }));
};

export const fetchPriceRange = async () => {
  const { prices } = await getRequest("/store/products");
  return [prices.min, prices.max];
};

export const fetchFilteredProducts = async ({ price, categories }) => {
  const { products } = await getRequest("/store/products", {
    price_min: price[0],
    price_max: price[1],
    categories,
  });
  return products;
};

export const fetchProductsByPrice = async (minPrice, maxPrice) => {
  try {
    const response = await getRequest(
      `/store/products?min-price=${minPrice}&max-price=${maxPrice}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      throw new Error(`Error fetching products: ${response.statusText}`);
    }

    const data = await response.json();
    return data.products; // Вернет массив отфильтрованных продуктов
  } catch (error) {
    console.error("Error fetching products by price:", error);
    throw error;
  }
};
