export const IconTelegram = () => (
  <svg
    width="17.000000"
    height="14.000000"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      id="Vector"
      d="M16.98 1.21C16.75 3.67 15.73 9.64 15.21 12.39C14.99 13.56 14.56 13.95 14.14 13.99C13.23 14.07 12.54 13.39 11.66 12.82C10.28 11.92 9.51 11.36 8.17 10.49C6.62 9.47 7.63 8.92 8.51 8.01C8.74 7.77 12.74 4.15 12.82 3.82C12.83 3.78 12.84 3.63 12.75 3.55C12.65 3.47 12.52 3.49 12.42 3.52C12.28 3.55 10.08 4.99 5.82 7.85C5.2 8.28 4.63 8.49 4.12 8.48C3.56 8.46 2.49 8.16 1.69 7.9C0.71 7.59 -0.07 7.42 0 6.88C0.03 6.6 0.42 6.32 1.16 6.02C5.72 4.05 8.77 2.74 10.29 2.11C14.64 0.32 15.54 0.01 16.13 0C16.87 -0.02 17.04 0.59 16.98 1.21L16.98 1.21Z"
      fill="#808080"
      fillOpacity="1.000000"
      fillRule="evenodd"
    />
  </svg>
);
