import { getRequest, postRequest } from "./index";
import { getDecryptClientData, saveCryptoClientData } from "../crypto";

export const customerRegistrationHandler = async (data, setError) => {
  const { token } = await postRequest("/auth/customer/emailpass/register", {
    email: data?.email,
    password: data?.password,
  });

  const userRegisterBody = {
    first_name: data?.firstName,
    last_name: data?.lastName,
    email: data?.email,
  };
  const { customer } = await postRequest(
    "/store/customers",
    userRegisterBody,
    token,
  );

  saveCryptoClientData(data?.email, data?.password);

  return { token, customer };
};

export const customerLoginHandler = async (email, password) => {
  const { token } = await postRequest("/auth/customer/emailpass", {
    email,
    password,
  });

  const { customer } = await getRequest("/store/customers/me", {}, token);
  await saveCryptoClientData(email, password);

  return { token, customer };
};

// TODO: delete after fixing problem with auth
export const tempRetrieveAuthToken = async () => {
  const clientData = getDecryptClientData();
  const { token } = await postRequest("/auth/customer/emailpass", {
    email: clientData.email,
    password: clientData.password,
  });

  return token;
};
