import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { PriceSlider } from '../../shared/PriceSlider';
import { CommonButton } from '../../shared/CommonButton';
import { CatalogCategoryFilter } from '../CatalogCategoryFilter';
import { fetchFilteredProducts } from '../../shared/libs/requests/product';
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredProducts } from '../../app/shopSlice';
import { filteredProductsState } from '../../app/constants';

import './index.scss';

export const CatalogProductFilter = () => {
  const viewportHeight = window.innerHeight;
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [categoryValues, setCategoryValues] = useState([]);
  const [showResetBtn, setShowResetBtn] = useState(false);
  const [isResetCategoryValues, setIsResetCategoryValues] = useState(true);
  const itemsAttributes = useSelector((state) => state.shop.itemsAttributes);
  const [priceFilterValues, setPriceFilterValues] = useState([0, 1000000]);
  const [priceRange, setPriceRange] = useState([0, 1000000]);

  const toggleDrawer = (newOpen) => () => setOpenDrawer(newOpen);

  const onChangeFilterValues = (values) => setPriceFilterValues(values);

  const onDetailsChangeHandler = (categoryId, itemType) => {
    setCategoryValues((prevValues) => {
      const updatedCategories = prevValues.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            list: category.list.map((el) => (el.type === itemType ? { ...el, isActive: !el.isActive } : el)),
          };
        }
        return category;
      });

      setIsResetCategoryValues(updatedCategories?.find((el) => el.isActive));
      return updatedCategories;
    });
  };

  const resetPriceFilter = () => setPriceFilterValues(priceRange);
  const resetCategoryFilter = () =>
    setCategoryValues(
      categoryValues?.map((category) => ({
        ...category,
        list: category.list?.map((el) => ({
          ...el,
          isActive: false,
        })),
      })),
    );

  const onResetBtnClick = () => {
    resetPriceFilter();
    resetCategoryFilter();
    dispatch(setFilteredProducts({ state: filteredProductsState.notFiltered, products: [] }));
  };

  const onApplyFilterBtnHandler = async () => {
    try {
      // Формируем массив активных фильтров
      const activeFilters = categoryValues.reduce((queryParams, filter) => {
        const activeDetails = filter.list.filter((item) => item.isActive).map((item) => item.type);

        if (activeDetails.length) {
          queryParams.push(`${filter.id}=${activeDetails.join(',')}`); // Используем filter.id как ключ
        }
        return queryParams;
      }, []);

      // Добавляем фильтр по цене (если есть)
      if (priceFilterValues[0] !== priceRange[0] || priceFilterValues[1] !== priceRange[1]) {
        const priceRange = [];
        if (priceFilterValues[0]) {
          priceRange.push(`minPrice=${priceFilterValues[0]}`);
        }
        if (priceFilterValues[1]) {
          priceRange.push(`maxPrice=${priceFilterValues[1]}`);
        }
        activeFilters.push(...priceRange);
      }

      // Формируем строку запроса
      const queryString = activeFilters.length ? `${activeFilters.join('&')}` : '';
      toggleDrawer(false)();

      const response = await fetchFilteredProducts(`${queryString}&limit=100&offset=0`);
      dispatch(setFilteredProducts({ state: filteredProductsState.filtered, products: response }));
    } catch (error) {
      console.error('Error applying filters:', error);
    }
  };

  useEffect(() => {
    dispatch(setFilteredProducts({ state: filteredProductsState.notFiltered, products: [] }));
  }, []);

  useEffect(() => {
    const isResetPriceFilter = !priceFilterValues.some((el, index) => el !== priceRange[index]);
    setShowResetBtn(!(isResetPriceFilter && isResetCategoryValues));
  }, [categoryValues, priceFilterValues]);

  useEffect(() => {
    if (itemsAttributes?.maxPrice) {
      const { maxPrice, ...filteredAttributes } = itemsAttributes;
      setPriceFilterValues((prev) => [prev[0], maxPrice]);
      setPriceRange((prev) => [prev[0], maxPrice]);
      setCategoryValues(Object.values(filteredAttributes));
    }
  }, [itemsAttributes]);

  return (
    <div className="catalog-product-filter">
      <Button className="filter-btn" onClick={toggleDrawer(true)}>
        <TuneIcon />
        Фильтры
      </Button>
      <Drawer open={openDrawer} className="filter-drawer" onClose={toggleDrawer(false)}>
        <Box style={{ height: `${viewportHeight - 48}px` }} role="presentation" className="filter-drawer-container">
          <div className="filter-drawer__title-box">
            <div className="filter-reset-button">
              <span className="main-title">Фильтры</span>
              {showResetBtn && (
                <span className="reset-btn" onClick={onResetBtnClick}>
                  сбросить
                </span>
              )}
            </div>
            <Button onClick={toggleDrawer(false)}>
              <CloseIcon />
            </Button>
          </div>
          <PriceSlider filterDefaultValues={priceRange} filterValues={priceFilterValues} onChangeValues={onChangeFilterValues} />
          <CatalogCategoryFilter categoryValues={categoryValues} onDetailsChange={onDetailsChangeHandler} />
          <CommonButton label="Показать товары" onClickHandler={onApplyFilterBtnHandler} />
        </Box>
      </Drawer>
    </div>
  );
};
