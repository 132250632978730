// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-user-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  margin-bottom: 164px;
}
.profile-user-info-container .tab-title {
  font-size: 30px;
  margin: 40px 0 10px 0;
  text-transform: uppercase;
}
.profile-user-info-container .MuiButton-root {
  margin-top: 40px;
}
.profile-user-info-container .MuiFormGroup-root.MuiRadioGroup-root {
  display: flex;
  flex-direction: row;
}
.profile-user-info-container .user-info__action-container {
  display: flex;
  gap: 20px;
}
.profile-user-info-container .user-info__action-container .user-info__save-btn {
  background: black;
  padding: 20px 30px;
  color: white !important;
}
.profile-user-info-container .user-info__action-container .user-info__exit-btn {
  padding: 20px 30px;
}`, "",{"version":3,"sources":["webpack://./src/features/ProfileUserInfoSection/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,oBAAA;AACF;AACE;EACE,eAAA;EACA,qBAAA;EACA,yBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;AADJ;AAIE;EACE,aAAA;EACA,SAAA;AAFJ;AAII;EACE,iBAAA;EACA,kBAAA;EACA,uBAAA;AAFN;AAKI;EACE,kBAAA;AAHN","sourcesContent":[".profile-user-info-container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  max-width: 500px;\n  margin-bottom: 164px;\n\n  & .tab-title {\n    font-size: 30px;\n    margin: 40px 0 10px 0;\n    text-transform: uppercase;\n  }\n\n  & .MuiButton-root {\n    margin-top: 40px;\n  }\n\n  & .MuiFormGroup-root.MuiRadioGroup-root {\n    display: flex;\n    flex-direction: row;\n  }\n\n  & .user-info__action-container {\n    display: flex;\n    gap: 20px;\n\n    & .user-info__save-btn {\n      background: black;\n      padding: 20px 30px;\n      color: white !important;\n    }\n\n    & .user-info__exit-btn {\n      padding: 20px 30px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
