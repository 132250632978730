// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  padding: 20px;
}
.price-slider__side-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 600;
}
.price-slider__side-values span {
  border-radius: 20px;
  background: #f5f5f5;
  padding: 6px 10px;
}`, "",{"version":3,"sources":["webpack://./src/shared/PriceSlider/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,MAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AACJ;AACI;EACE,mBAAA;EACA,mBAAA;EACA,iBAAA;AACN","sourcesContent":[".price-slider {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 0;\n  padding: 20px;\n\n  &__side-values {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    font-weight: 600;\n\n    & span {\n      border-radius: 20px;\n      background: #f5f5f5;\n      padding: 6px 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
