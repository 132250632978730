import React, { useEffect } from "react";
import { MedusaProvider } from "medusa-react";
import { QueryClient } from "@tanstack/react-query";
import { Routing } from "../pages";
import { createTheme, ThemeProvider } from "@mui/material";
import { useDispatch } from "react-redux";
import { setUser } from "./userSlice";

import "./index.css";

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const userStorage = localStorage.getItem("user");
      if (userStorage) {
        dispatch(setUser(JSON.parse(userStorage)));
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div className="App">
      <MedusaProvider
        queryClientProviderProps={{ client: queryClient }}
        baseUrl={process.env.REACT_APP_MEDUSA_BACKEND_URL}
        publishableApiKey={process.env.REACT_APP_PUBLISH_API_KEY}
      >
        <ThemeProvider theme={theme}>
          <Routing />
        </ThemeProvider>
      </MedusaProvider>
    </div>
  );
};

export default App;
