import React from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { RoundedButton } from "../../shared/RoundedButton";

import "./index.scss";

export const ProfileUserNotificationOptionsSection = () => (
  <div className="profile-user-notification-option-container">
    <p className="tab-title">
      Выберите удобный способ получения рекламных сообщений
    </p>
    <FormGroup>
      <FormControlLabel
        control={<Switch defaultChecked />}
        label="Push-уведомления"
      />
      <FormControlLabel control={<Switch />} label="E-mail" />
      <FormControlLabel control={<Switch />} label="SMS и мессенджеры" />
    </FormGroup>
    <div>
      <RoundedButton>Выбрать</RoundedButton>
    </div>
  </div>
);
