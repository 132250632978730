import React, { useState } from "react";
import {
  Breadcrumbs,
  Grid,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useProduct, useProducts } from "medusa-react";
import { RoundedButton } from "../../shared/RoundedButton";
import { CatalogProductCard } from "../CatalogProductCard";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Box from "@mui/material/Box";

import "./index.scss";

export const SelectedProductForm = () => {
  let { prodId } = useParams();
  const { product } = useProduct(prodId);
  const { products, isLoading } = useProducts();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function createData(name, value) {
    return { name, value };
  }

  const rows = [
    createData("Тип продукта", product?.metadata?.productType),
    createData("Для кого", product?.metadata?.clientType),
    createData("Страна производства", product?.metadata?.brand),
    createData("Назначение", product?.metadata?.purpose),
    createData("Количество", product?.metadata?.count),
    createData("Возраст", product?.metadata?.age),
  ];

  return (
    <div className="main-container">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          MUI
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/material-ui/getting-started/installation/"
        >
          Core
        </Link>
        <Typography color="text.primary">Breadcrumbs</Typography>
      </Breadcrumbs>
      <div className="product-container">
        <img
          className="product-container__img"
          src={product?.thumbnail}
          alt={product?.title}
        />
        <div className="product-details">
          <p className="product-details__description">
            {product?.collection?.title}
          </p>
          <p className="product-details__title">{product?.title}</p>
          <div className="product-details__volume-box">
            <div className="volume-container">
              <span>30</span>
              <span>60</span>
              <span>90</span>
            </div>
            <p>
              <b>Medicated Gel Cleanser,</b> объем, мл
            </p>
          </div>
          <span className="product-details__price">
            {product?.variants?.map((variant) => (
              <li key={variant.id}>
                {(variant?.prices[0]?.amount / 100).toFixed(0)} ₽
              </li>
            ))}
          </span>
          <RoundedButton className="product-details__cart">
            <ShoppingBasketIcon />
            Добавить в корзину
          </RoundedButton>
        </div>
      </div>
      <div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Описание" {...a11yProps(0)} />
            <Tab label="Бренд" {...a11yProps(1)} />
            <Tab label="Способ применения" {...a11yProps(2)} />
            <Tab label="Состав" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <h3>{product?.title}</h3>
          <p>{product?.description}</p>
          <TableContainer component={Paper} sx={{ marginTop: "40px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <p>{product?.metadata?.brand}</p>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <p>{product?.metadata?.modeOfApplication}</p>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <p>{product?.metadata?.mainComponent}</p>
        </CustomTabPanel>
      </div>
      <div>
        <p>Похожие товары</p>
        <Grid container spacing={4}>
          {products?.map((product) => (
            <CatalogProductCard key={product.id} product={product} xs={3} />
          ))}
        </Grid>
      </div>
    </div>
  );
};
