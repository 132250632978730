import axios from "axios";
import { getLocalItem } from "../localStorage";

export const postRequest = async (url, body, token) => {
  const { data } = await axios.post(
    process.env.REACT_APP_MEDUSA_BACKEND_URL + url,
    body,
    {
      headers: {
        Authorization: `Bearer ${token || getLocalItem("token")}`,
        "Content-Type": "application/json",
        "x-publishable-api-key": process.env.REACT_APP_PUBLISH_API_KEY,
        credentials: "include",
      },
    },
  );
  return data;
};

export const getRequest = async (url, params, token) => {
  const { data } = await axios.get(
    process.env.REACT_APP_MEDUSA_BACKEND_URL + url,
    {
      headers: {
        Authorization: `Bearer ${token || getLocalItem("token")}`,
        "Content-Type": "application/json",
        "x-publishable-api-key": process.env.REACT_APP_PUBLISH_API_KEY,
        credentials: "include",
      },
      params,
    },
  );
  return data;
};

export const deleteRequest = async (url, token) => {
  const { data } = await axios.delete(
    process.env.REACT_APP_MEDUSA_BACKEND_URL + url,
    {
      headers: {
        Authorization: `Bearer ${token || getLocalItem("token")}`,
        "x-publishable-api-key": process.env.REACT_APP_PUBLISH_API_KEY,
        credentials: "include",
      },
    },
  );
  return data;
};
