export const IconYoutube = () => (
  <svg
    width="17.756348"
    height="12.510742"
    viewBox="0 0 17.7563 12.5107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      id="Vector"
      d="M17.38 1.95C17.18 1.18 16.57 0.57 15.81 0.37C14.43 0 8.87 0 8.87 0C8.87 0 3.32 0 1.94 0.37C1.17 0.57 0.57 1.18 0.37 1.95C0 3.34 0 6.25 0 6.25C0 6.25 0 9.16 0.37 10.55C0.57 11.32 1.17 11.93 1.94 12.13C3.32 12.51 8.87 12.51 8.87 12.51C8.87 12.51 14.43 12.51 15.81 12.13C16.57 11.93 17.18 11.32 17.38 10.55C17.75 9.16 17.75 6.25 17.75 6.25C17.75 6.25 17.75 3.34 17.38 1.95ZM7.06 8.89L7.06 3.61L11.7 6.25L7.06 8.89L7.06 8.89Z"
      fill="#808080"
      fillOpacity="1.000000"
      fillRule="nonzero"
    />
  </svg>
);
