// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-user-faq-container {
  display: flex;
  margin-top: 64px;
  margin-bottom: 164px;
  gap: 20px;
}
.profile-user-faq-container .user-faq-navbar-container {
  width: 320px;
  flex: 1 0 auto;
  position: sticky;
  top: 0;
  height: 100%;
}
.profile-user-faq-container .user-faq-navbar-container .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
  min-height: unset;
}
.profile-user-faq-container .user-faq-navbar-container .MuiAccordion-root {
  border: none;
  box-shadow: none;
}
.profile-user-faq-container .user-faq-navbar-container .MuiAccordion-root .accordion-title {
  font-weight: 600;
}
.profile-user-faq-container .user-faq-navbar-container .MuiAccordion-root::before {
  display: none;
}
.profile-user-faq-container .user-faq-content-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}`, "",{"version":3,"sources":["webpack://./src/features/ProfileUserFAQSection/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,oBAAA;EACA,SAAA;AACF;AACE;EACE,YAAA;EACA,cAAA;EACA,gBAAA;EACA,MAAA;EACA,YAAA;AACJ;AACI;EACE,SAAA;EACA,iBAAA;AACN;AACI;EACE,YAAA;EACA,gBAAA;AACN;AACM;EACE,gBAAA;AACR;AAEM;EACE,aAAA;AAAR;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHJ","sourcesContent":[".profile-user-faq-container {\n  display: flex;\n  margin-top: 64px;\n  margin-bottom: 164px;\n  gap: 20px;\n\n  & .user-faq-navbar-container {\n    width: 320px;\n    flex: 1 0 auto;\n    position: sticky;\n    top: 0;\n    height: 100%;\n\n    & .MuiAccordionSummary-content.Mui-expanded {\n      margin: 0;\n      min-height: unset;\n    }\n    & .MuiAccordion-root {\n      border: none;\n      box-shadow: none;\n\n      & .accordion-title {\n        font-weight: 600;\n      }\n\n      &::before {\n        display: none;\n      }\n    }\n  }\n\n  & .user-faq-content-container {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
