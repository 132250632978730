// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-panel-form__title {
  font-size: 30px !important;
  font-weight: 600 !important;
  line-height: 37px !important;
}
.auth-panel-form__submit-btn {
  border-radius: 20px !important;
}

.MuiTextField-root {
  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 25px;
  background: rgb(255, 255, 255);
  margin-top: 0;
}
.MuiTextField-root .MuiInputBase-root {
  border-radius: 20px;
}
.MuiTextField-root .MuiInputBase-input {
  color: rgb(0, 0, 0);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding: 10px;
}
.MuiTextField-root .MuiFormLabel-root {
  color: rgb(0, 0, 0);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.MuiTextField-root .MuiFormHelperText-root {
  color: rgb(0, 0, 0);
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.MuiDrawer-paperAnchorRight {
  margin: 20px;
  border-radius: 25px;
  height: calc(100vh - 40px) !important;
  max-width: 640px;
}`, "",{"version":3,"sources":["webpack://./src/features/AuthPanelForm/index.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;AAAJ;AAGE;EACE,8BAAA;AADJ;;AAKA;EACE,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;AAFF;AAIE;EACE,mBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;AAHJ;AAME;EACE,mBAAA;EACA,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAJJ;AAOE;EACE,mBAAA;EACA,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AALJ;;AASA;EACE,YAAA;EACA,mBAAA;EACA,qCAAA;EACA,gBAAA;AANF","sourcesContent":[".auth-panel-form {\n  &__title {\n    font-size: 30px !important;\n    font-weight: 600 !important;\n    line-height: 37px !important;\n  }\n\n  &__submit-btn {\n    border-radius: 20px !important;\n  }\n}\n\n.MuiTextField-root {\n  box-sizing: border-box;\n  border: 1px solid rgb(0, 0, 0);\n  border-radius: 25px;\n  background: rgb(255, 255, 255);\n  margin-top: 0;\n\n  .MuiInputBase-root{\n    border-radius: 20px;\n  }\n\n  .MuiInputBase-input {\n    color: rgb(0, 0, 0);\n    font-family: Montserrat, sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 17px;\n    padding: 10px;\n  }\n\n  .MuiFormLabel-root {\n    color: rgb(0, 0, 0);\n    font-family: Montserrat, sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 24px;\n  }\n\n  .MuiFormHelperText-root {\n    color: rgb(0, 0, 0);\n    font-family: Montserrat, sans-serif;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 14px;\n  }\n}\n\n.MuiDrawer-paperAnchorRight {\n  margin: 20px;\n  border-radius: 25px;\n  height: calc(100vh - 40px) !important;\n  max-width: 640px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
