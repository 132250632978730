import React, { useEffect, useState } from "react";
import discountIcon from "../../media/discount-icon.png";
import { RoundedButton } from "../../shared/RoundedButton";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setShowAuthForm } from "../../app/userSlice";

import "./index.scss";

export const DiscountHeaderCapForm = () => {
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user.user);
  const [showDiscountCap, setShowDiscountCap] = useState(false);

  const openAuthModal = () => dispatch(setShowAuthForm("login"));

  const closeDiscountCap = () => setShowDiscountCap(false);

  useEffect(() => {
    if (userStore && !userStore?.id) setShowDiscountCap(true);
  }, [userStore?.id]);

  return (
    <>
      {showDiscountCap ? (
        <div className="discount-cap">
          <div className="discount-cap__content">
            <span className="discount-cap__icon">
              <img src={discountIcon} alt="discount-icon" />
            </span>
            <span className="discount-cap__text">
              Скидочная карта 5% при регистрации!
            </span>
            <RoundedButton onClick={openAuthModal}>Получить</RoundedButton>
          </div>
          <Close
            className="discount-cap__close-btn"
            onClick={closeDiscountCap}
          />
        </div>
      ) : null}
    </>
  );
};
