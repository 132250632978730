import React, { useMemo } from "react";
import { RoundedButton } from "../../shared/RoundedButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { formatPrice, saveUserInfo } from '../../shared/libs/helpers';
import { getLocalItem } from "../../shared/libs/localStorage";

import "./index.scss";

export const CatalogProductCard = ({ product, xs, addToCartHandler }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user.user);

  const onProductNavigate = () => navigate(`/product/${product.id}`);

  const addFavouriteHandler = async () => {
    if (!userStore?.id) {
      alert("Необходимо авторизоваться");
      return;
    }

    const token = getLocalItem("token");

    let updatedFavList = [];
    if (!userStore?.metadata?.favoriteItems) updatedFavList = [product?.id];
    else {
      updatedFavList = userStore?.metadata?.favoriteItems.includes(product?.id)
        ? userStore.metadata.favoriteItems.filter((el) => el !== product?.id)
        : [...userStore.metadata.favoriteItems, product?.id];
    }

    axios
      .post(
        `${process.env.REACT_APP_MEDUSA_BACKEND_URL}/store/customers/me`,
        { metadata: { favoriteItems: updatedFavList } },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-publishable-api-key": process.env.REACT_APP_PUBLISH_API_KEY,
          },
        },
      )
      .then((response) => {
        saveUserInfo(response?.data?.customer, dispatch);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных клиента:", error);
      });
  };

  const selectedVariantPrice = useMemo(() => {
    if (product?.variants?.length) {
      return product.variants.find((el) => el.calculated_price); // Берём вариант у которого есть цен как дефолтный
    }
    return null;
  }, [product]);

  const price = selectedVariantPrice?.calculated_price?.calculated_amount
    ? formatPrice(selectedVariantPrice.calculated_price.calculated_amount)
    : "N/A";

  const originalPrice = selectedVariantPrice?.calculated_price?.original_amount
    ? formatPrice(selectedVariantPrice.calculated_price.original_amount)
    : null;

  return (
    <Grid item xs={xs} spacing={4} className="catalog-product-card">
      {!userStore?.metadata?.favoriteItems?.includes(product?.id) ? (
        <FavoriteBorder
          className="add-to-favorite-btn"
          onClick={addFavouriteHandler}
        />
      ) : (
        <Favorite
          className="add-to-favorite-btn"
          onClick={addFavouriteHandler}
        />
      )}
      <div
        className="catalog-product-card__image-container"
        onClick={onProductNavigate}
      >
        <span
          style={{ backgroundImage: `url(${product?.images?.[0]?.url})` }}
        />
      </div>
      <p className="catalog-product-card__section" onClick={onProductNavigate}>
        {product?.description}
      </p>
      <p className="catalog-product-card__title" onClick={onProductNavigate}>
        {product?.title}
      </p>
      <div className="catalog-product-card__button-box">
        <RoundedButton onClick={() => addToCartHandler(product)}>
          <ShoppingCartIcon />
          <span>{price}</span>
        </RoundedButton>
      </div>
    </Grid>
  );
};
