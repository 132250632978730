import { getLocalItem, setLocalItem } from "../localStorage";

const CryptoJS = require("crypto-js");

function encryptData(data) {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY,
  ).toString();
}

function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(
    encryptedData,
    process.env.REACT_APP_SECRET_KEY,
  );
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export function saveCryptoClientData(email, password) {
  const clientData = { email, password };
  const encryptedData = encryptData(clientData);

  setLocalItem("clientData", encryptedData);
}

export function getDecryptClientData() {
  const encryptedData = getLocalItem("clientData");
  if (encryptedData) return decryptData(encryptedData);
  else {
    console.log("Данные клиента не найдены.");
    return {};
  }
}
