import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { Link, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { i18n } from "../../app/verbs";
import Medusa from "@medusajs/medusa-js";
import { setShowAuthForm, setUser } from "../../app/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./index.scss";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const showAuthForm = useSelector((state) => state.user.showAuth);

  const navigate = useNavigate();
  const medusa = new Medusa({
    baseUrl: process.env.REACT_APP_MEDUSA_BACKEND_URL,
    maxRetries: 3,
  });
  const [serverError, setServerError] = useState("");

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(i18n.validation.email.invalid)
      .required(i18n.validation.email.required),
    password: Yup.string()
      .min(8, i18n.validation.password.minLength)
      .required(i18n.validation.password.required),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
  });

  const toggleDrawer = (newOpen) => () => dispatch(setShowAuthForm(newOpen));

  const handleLogin = async () => {
    formik.setTouched({
      email: true,
      password: true,
    });

    const errors = await formik.validateForm();

    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      medusa.auth
        .getToken({
          email: formik.values.email,
          password: formik.values.password,
        })
        .then(({ access_token }) => {
          localStorage.setItem("token", access_token);
        });

      medusa.auth
        .authenticate({
          email: formik.values.email,
          password: formik.values.password,
        })
        .then(({ customer }) => {
          localStorage.setItem("user", JSON.stringify(customer));
          dispatch(setUser(customer));
          dispatch(setShowAuthForm(null));
          navigate("/profile");
        });
    } catch (error) {
      console.error("Ошибка авторизации:", error);
    }
  };

  // const handleLogin = async () => {
  //   formik.setTouched({
  //     email: true,
  //     password: true,
  //   });
  //
  //   const errors = await formik.validateForm();
  //
  //   if (Object.keys(errors).length > 0) {
  //     return;
  //   }
  //
  //   medusa.auth
  //     .authenticate({
  //       email: formik.values.email,
  //       password: formik.values.password,
  //     })
  //     .then(({ customer }) => {
  //       try {
  //         localStorage.setItem("user", JSON.stringify(customer));
  //         dispatch(setUser(customer));
  //         navigate("/profile");
  //         setShowAuthPanel("");
  //       } catch (e) {
  //         console.error(e);
  //       }
  //     });
  // };

  return (
    <div className="login-form">
      <Drawer
        open={showAuthForm === 'login'}
        anchor="right"
        className="filter-drawer"
        onClose={toggleDrawer("")}
      >
        <Box
          role="presentation"
          className="filter-drawer-container"
          sx={{ width: "348px", gap: "unset" }}
        >
          <Typography className="login-form__title" variant="h5" gutterBottom>
            Вход
          </Typography>
          {serverError && (
            <Typography variant="body2" color="error" gutterBottom>
              {serverError}
            </Typography>
          )}
          <TextField
            fullWidth
            id="email"
            name="email"
            size="small"
            label="Электронная почта"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Пароль"
            type="password"
            size="small"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            margin="normal"
          />
          <Typography
            variant="body2"
            fontSize="12px"
            color="gray"
            sx={{ mt: 2 }}
          >
            Нажимая на кнопку "Войти", я подтверждаю свое согласие на обработку
            моих персональных данных в соответствии с{" "}
            <Link href="/privacy-policy" target="_blank">
              Политикой обработки персональных данных
            </Link>
            ,{" "}
            <Link href="/data-processing-consent" target="_blank">
              Согласием на обработку персональных данных
            </Link>
            , а также в соответствии с{" "}
            <Link href="/user-agreement" target="_blank">
              Пользовательским соглашением
            </Link>
            .
          </Typography>
          <Button
            color="primary"
            variant="contained"
            className="login-form__submit-btn"
            fullWidth
            type="submit"
            sx={{ mt: 2 }}
            onClick={handleLogin}
          >
            Войти
          </Button>
          <Button
            color="inherit"
            fullWidth
            className="login-form__submit-btn"
            sx={{ mt: 1 }}
            onClick={() => dispatch(setShowAuthForm('create'))}
          >
            Зарегистрироваться
          </Button>
        </Box>
      </Drawer>
    </div>
  );
};
