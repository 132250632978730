import React, { useEffect, useState } from "react";
import { Grid, Button, Box } from "@mui/material";
import { CatalogProductCard } from "../../features/CatalogProductCard";
import { CatalogProductFilter } from "../../features/CatalogProductFilter";
import { AddItemToCartPopup } from "../../features/AddItemToCartPopup";
import { CardSkeletonForm } from "../../features/CardSkeletonForm";
import { useProducts } from "../../shared/libs/requests/product";
import { addLineItemToCart, createCart } from "../../shared/libs/requests/cart";
import { setCart } from "../../app/shopSlice";
import { useDispatch } from "react-redux";
import { setLocalItem } from "../../shared/libs/localStorage";

import "./index.scss";

export const CatalogForm = ({ catalogId }) => {
  const dispatch = useDispatch();
  const productLimit = 100;
  const {
    products: { products, count: totalCount },
    loading,
    error,
    refetch,
    loadMore,
  } = useProducts({
    category_id: catalogId,
    fields: `*variants.calculated_price`,
    country_code: "RU",
    limit: productLimit,
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [addedProduct, setAddedProduct] = useState(null);
  const [cartId, setCartId] = useState(null);

  const addToCartHandler = async (productData) => {
    setAddedProduct(productData);
    console.log("Adding product to cart:", productData);

    try {
      let cart = {};
      if (!cartId) {
        // Создаем новую корзину, если ее еще нет
        cart = await createCart([
          {
            variant_id: productData.variants[0].id,
            quantity: 1,
            currency_code: "rub",
          },
        ]);
        setCartId(cart.id);
        console.log("Cart created:", cart);
      } else {
        // Добавляем товар в существующую корзину
        cart = await addLineItemToCart(cartId, {
          variant_id: productData.variants[0].id,
          quantity: 1,
        });
        console.log("Cart updated:", cart);
      }
      setShowSnackbar(true);
      dispatch(setCart(cart));
      setLocalItem("cart", cart);
      setTimeout(() => setShowSnackbar(false), 3000);
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  const getMaxItemPrice = () => {
    //TODO: добавить значение из фильтра
    return 1200;
  };

  useEffect(() => {
    if (catalogId) refetch();
  }, [catalogId]);

  return (
    <div className="catalog-form main-container">
      <AddItemToCartPopup
        triggerSnackbar={showSnackbar}
        product={addedProduct}
      />
      <CatalogProductFilter defaultValues={[0, getMaxItemPrice()]} />
      {products && products.length > 0 ? (
        <Grid container spacing={2}>
          {products.map((product) => (
            <CatalogProductCard
              key={product.id}
              product={product}
              addToCartHandler={addToCartHandler}
              xs={3}
            />
          ))}
        </Grid>
      ) : (
        <CardSkeletonForm cardsCount={16} />
      )}
      {loading && <CardSkeletonForm cardsCount={16} />}
      {!loading && products.length < totalCount && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            variant="contained"
            onClick={() => loadMore(products?.length, productLimit)}
            endIcon={<span style={{ transform: "rotate(90deg)" }}>➔</span>}
          >
            Показать еще
          </Button>
        </Box>
      )}
    </div>
  );
};
