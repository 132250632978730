import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { Link, TextField } from "@mui/material";
import { useCreateCustomer } from "medusa-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { i18n } from "../../app/verbs";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { setShowAuthForm } from "../../app/userSlice";

import "./index.scss";

export const AuthPanelForm = () => {
  const dispatch = useDispatch();
  const showAuthForm = useSelector((state) => state.user.showAuth);

  const createCustomer = useCreateCustomer();
  const [serverError, setServerError] = useState("");

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(i18n.validation.email.invalid)
      .required(i18n.validation.email.required),
    phone: Yup.string().matches(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      i18n.validation.phone.invalid,
    ),
    password: Yup.string()
      .min(8, i18n.validation.password.minLength)
      .required(i18n.validation.password.required),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      password: "",
    },
    validationSchema: validationSchema,
  });

  const toggleDrawer = (newOpen) => () => dispatch(setShowAuthForm(newOpen));

  const handleCreateUser = async () => {
    formik.setTouched({
      email: true,
      phone: true,
      password: true,
    });

    const errors = await formik.validateForm();

    if (Object.keys(errors).length > 0) return;

    const customerData = {
      phone: formik.values.phone,
      email: formik.values.email,
      password: formik.values.password,
    };

    createCustomer.mutate(customerData, {
      onSuccess: ({ customer }) => {
        console.log(customer.id);
        dispatch(setShowAuthForm(null));
      },
      onError: (error) => {
        if (error.response && error.response.data.message.includes("email")) {
          setServerError("Эта электронная почта уже зарегистрирована.");
        } else {
          console.error("Ошибка:", error.message);
          setServerError("Произошла ошибка. Попробуйте еще раз.");
        }
      },
    });
  };

  return (
    <div className="auth-panel-form">
      <Drawer
        open={showAuthForm === 'create'}
        anchor="right"
        className="filter-drawer"
        onClose={toggleDrawer("")}
      >
        <Box
          role="presentation"
          className="filter-drawer-container"
          sx={{ width: "348px", gap: "unset" }}
        >
          <Typography
            className="auth-panel-form__title"
            variant="h5"
            gutterBottom
          >
            Войти или зарегистрироваться
          </Typography>
          {serverError && (
            <Typography variant="body2" color="error" gutterBottom>
              {serverError}
            </Typography>
          )}
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Электронная почта"
            size="small"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />
          <InputMask
            id="phone"
            name="phone"
            mask="+7 (999) 999-99-99"
            size="small"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.phone && formik.errors.phone}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                label="Телефон"
                fullWidth
                margin="normal"
              />
            )}
          </InputMask>
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Пароль"
            type="password"
            size="small"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            margin="normal"
          />
          <Typography
            variant="body2"
            fontSize="12px"
            color="gray"
            sx={{ mt: 2 }}
          >
            Нажимая на кнопку "Создать", я подтверждаю свое согласие на
            обработку моих персональных данных в соответствии с{" "}
            <Link href="/privacy-policy" target="_blank">
              Политикой обработки персональных данных
            </Link>
            ,{" "}
            <Link href="/data-processing-consent" target="_blank">
              Согласием на обработку персональных данных
            </Link>
            , а также в соответствии с{" "}
            <Link href="/user-agreement" target="_blank">
              Пользовательским соглашением
            </Link>
            .
          </Typography>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            className="auth-panel-form__submit-btn"
            type="submit"
            sx={{ mt: 2 }}
            onClick={handleCreateUser}
          >
            Создать
          </Button>
          <Button
            color="inherit"
            fullWidth
            className="auth-panel-form__submit-btn"
            sx={{ mt: 1 }}
            onClick={() => dispatch(setShowAuthForm("login"))}
          >
            У меня уже есть аккаунт
          </Button>
        </Box>
      </Drawer>
    </div>
  );
};
