// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton .MuiSkeleton-root {
  border-radius: 10px;
}
.skeleton .img {
  width: 100%;
  height: 200px;
  border-radius: 20px;
}

.skeleton.minimalistic {
  display: flex;
  gap: 10px;
  padding: 0 !important;
}
.skeleton.minimalistic .img {
  width: 75px;
  height: 75px;
}`, "",{"version":3,"sources":["webpack://./src/shared/CardSkeleton/index.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AADJ;;AAKA;EACE,aAAA;EACA,SAAA;EACA,qBAAA;AAFF;AAIE;EACE,WAAA;EACA,YAAA;AAFJ","sourcesContent":[".skeleton {\n  .MuiSkeleton-root {\n    border-radius: 10px;\n  }\n\n  & .img {\n    width: 100%;\n    height: 200px;\n    border-radius: 20px;\n  }\n}\n\n.skeleton.minimalistic {\n  display: flex;\n  gap: 10px;\n  padding: 0!important;\n\n  & .img {\n    width: 75px;\n    height: 75px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
