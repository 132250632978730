import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, InputBase, Skeleton } from "@mui/material";
import { MinimalisticProductCard } from "../MinimalisticProductCard";
import { useNavigate } from "react-router-dom";
import { CardSkeletonForm } from "../CardSkeletonForm";
import { getFilteredProduct } from "../../shared/libs/requests/product";
import { useDispatch, useSelector } from "react-redux";
import { setPopularItems } from "../../app/shopSlice";

import "./index.scss";

export const HeaderSearchField = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const popularItems = useSelector((state) => state.shop.popularItems);

  const onChangeInputHandler = async (value) => {
    setSearchValue(value);
    try {
      setIsSearchLoading(true);
      const filteredProducts = await getFilteredProduct({ q: value });
      setFilteredList(filteredProducts);
    } catch (error) {
      console.error("Search Error:", error);
    } finally {
      setIsSearchLoading(false);
    }
  };

  const onCloseSearchPopup = () => {
    setSearchValue("");
    setFilteredList([]);
  };

  const onProductNavigate = (product) => {
    onCloseSearchPopup();
    navigate(`/product/${product.id}`);
  };

  useEffect(() => {
    if (!popularItems?.length) {
      const fetchProducts = async () => {
        try {
          const products = await getFilteredProduct({ limit: 4 });
          dispatch(setPopularItems(products));
        } catch (error) {
          console.error("Error fetching popular products:", error);
        }
      };
      fetchProducts();
    }
  }, [dispatch, popularItems?.length]);

  return (
    <div className="search-field">
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Поиск"
        value={searchValue}
        inputProps={{ "aria-label": "Поиск" }}
        onChange={(e) => onChangeInputHandler(e.target.value)}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon sx={{ color: "black" }} />
      </IconButton>
      {searchValue ? (
        <div className="search-result">
          {isSearchLoading ? (
            <div className="search-skeleton__container">
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="60%" />
            </div>
          ) : filteredList.length ? (
            filteredList?.map((item, index) =>
              index < 10 ? (
                <span key={item.title} onClick={() => onProductNavigate(item)}>
                  {item.title}
                </span>
              ) : null,
            )
          ) : (
            <span className="empty-list">нет результатов...</span>
          )}
          <div className="search-popular-items">
            <p className="title">Популярные товары</p>
            {popularItems && popularItems.length > 0 ? (
              <Grid container spacing={4}>
                {popularItems &&
                  popularItems.length > 0 &&
                  popularItems.map((product) => (
                    <MinimalisticProductCard
                      key={product.id}
                      product={product}
                      xs={6}
                      onCloseSearch={onCloseSearchPopup}
                    />
                  ))}
              </Grid>
            ) : (
              <CardSkeletonForm cardsCount={4} xsCount={6} isMinimalistic />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
