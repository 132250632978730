import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import { PriceSlider } from "../../shared/PriceSlider";
import CloseIcon from "@mui/icons-material/Close";
import { CommonButton } from "../../shared/CommonButton";
import { CatalogCategoryFilter } from "../CatalogCategoryFilter";
import { filterCategories } from "../../app/mocks";

import "./index.scss";

export const CatalogProductFilter = ({ defaultValues }) => {
  const viewportHeight = window.innerHeight;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [priceFilterValues, setPriceFilterValues] = useState([
    0,
    defaultValues[1],
  ]);
  const [categoryValues, setCategoryValues] = useState(filterCategories);
  const [showResetBtn, setShowResetBtn] = useState(false);
  const [isResetCategoryValues, setIsResetCategoryValues] = useState(true);

  const toggleDrawer = (newOpen) => () => setOpenDrawer(newOpen);
  const onChangeFilterValues = (values) => setPriceFilterValues(values);
  const onApplyFilterBtnHandler = () => {};
  const findDetailsWithActiveStatus = (categoryValues) =>
    !categoryValues.some((el) => el.detailList.some((el) => el.isActive));
  const onDetailsChangeHandler = (summaryId, detailId) => {
    const getChangedCategories = (categoryValues) =>
      categoryValues.map((category) =>
        category.id === summaryId
          ? {
              ...category,
              detailList: category.detailList.map((detail) =>
                detail.id === detailId
                  ? {
                      ...detail,
                      isActive: !detail.isActive,
                    }
                  : detail,
              ),
            }
          : category,
      );

    setCategoryValues((prev) => {
      const changedCategories = getChangedCategories(prev);
      setIsResetCategoryValues(findDetailsWithActiveStatus(changedCategories));

      return changedCategories;
    });
  };
  const resetPriceFilter = () => setPriceFilterValues([0, defaultValues[1]]);
  const resetCategoryFilter = () => setCategoryValues(filterCategories);
  const onResetBtnClick = () => {
    resetPriceFilter();
    resetCategoryFilter();
  };

  useEffect(() => {
    const isResetPriceFilter = !priceFilterValues.some(
      (el, index) => el !== defaultValues[index],
    );
    setShowResetBtn(!(isResetPriceFilter && isResetCategoryValues));
  }, [categoryValues, priceFilterValues]);

  return (
    <div className="catalog-product-filter">
      <Button className="filter-btn" onClick={toggleDrawer(true)}>
        <TuneIcon />
        Фильтры
      </Button>
      <Drawer
        open={openDrawer}
        className="filter-drawer"
        onClose={toggleDrawer(false)}
      >
        <Box
          style={{ height: `${viewportHeight - 48}px` }}
          role="presentation"
          className="filter-drawer-container"
        >
          <div className="filter-drawer__title-box">
            <div className="filter-reset-button">
              <span className="main-title">Фильтры</span>
              {showResetBtn ? (
                <span className="reset-btn" onClick={onResetBtnClick}>
                  сбросить
                </span>
              ) : null}
            </div>
            <Button onClick={toggleDrawer(false)}>
              <CloseIcon />
            </Button>
          </div>
          <PriceSlider
            filterDefaultValues={defaultValues}
            filterValues={priceFilterValues}
            onChangeValues={onChangeFilterValues}
          />
          <CatalogCategoryFilter
            categoryValues={categoryValues}
            onDetailsChange={onDetailsChangeHandler}
          />
          <CommonButton
            label="Показать товары"
            onClickHandler={onApplyFilterBtnHandler}
          />
        </Box>
      </Drawer>
    </div>
  );
};
