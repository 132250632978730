export const filterCategories = [
  {
    id: 0,
    summaryTitle: "бренд",
    detailList: [
      { id: 0, label: "Now Foods", isActive: false },
      { id: 1, label: "Nature's Way", isActive: false },
      { id: 2, label: "Solgar", isActive: false },
      { id: 3, label: "Centrum", isActive: false },
      { id: 4, label: "Optimum Nutrition", isActive: false },
      { id: 5, label: "Garden of Life", isActive: false },
    ],
  },
  {
    id: 1,
    summaryTitle: "тип продукта",
    detailList: [
      { id: 0, label: "Витамины", isActive: false },
      { id: 1, label: "Минералы", isActive: false },
      { id: 2, label: "Аминокислоты", isActive: false },
      { id: 3, label: "Жиросжигатели", isActive: false },
      { id: 4, label: "Белковые смеси", isActive: false },
      { id: 5, label: "Омега-3", isActive: false },
    ],
  },
  {
    id: 2,
    summaryTitle: "область применения",
    detailList: [
      { id: 0, label: "Укрепление иммунитета", isActive: false },
      { id: 1, label: "Энергия и бодрость", isActive: false },
      { id: 2, label: "Здоровье сердца", isActive: false },
      { id: 3, label: "Улучшение памяти", isActive: false },
      { id: 4, label: "Здоровье кожи", isActive: false },
    ],
  },
];

export const categoryList = [
  {
    category: "Суставы и мыщцы",
    subcategories: [
      {
        subcategory: "Витамины",
        items: [
          "Витамин A",
          "Витамин B",
          "Витамин C",
          "Витамин D",
          "Витамин E",
        ],
      },
      {
        subcategory: "Минералы",
        items: ["Кальций", "Магний", "Железо", "Цинк", "Селен"],
      },
    ],
  },
  {
    category: "Иммунитет",
    subcategories: [
      {
        subcategory: "Омега-3",
        items: ["Рыбий жир", "Масло криля"],
      },
      {
        subcategory: "Другие жирные кислоты",
        items: ["Омега-6", "Омега-9"],
      },
    ],
  },
  {
    category: "Очищение кожи и крови",
    subcategories: [
      {
        subcategory: "Глюкозамин",
        items: [],
      },
      {
        subcategory: "Хондроитин",
        items: [],
      },
      {
        subcategory: "МСМ (Метилсульфонилметан)",
        items: [],
      },
      {
        subcategory: "Комплексные добавки для суставов",
        items: [],
      },
    ],
  },
  {
    category: "Мочеполовая система",
    subcategories: [
      {
        subcategory: "Антиоксиданты",
        items: ["Витамин C", "Витамин E", "Коэнзим Q10"],
      },
      {
        subcategory: "Иммуномодуляторы",
        items: ["Эхинацея", "Астрагал"],
      },
      {
        subcategory: "Пробиотики",
        items: [],
      },
    ],
  },
  {
    category: "Стресс; нарушение сна",
    subcategories: [
      {
        subcategory: "Кофеин",
        items: [],
      },
      {
        subcategory: "Женьшень",
        items: [],
      },
      {
        subcategory: "Б-комплекс",
        items: [],
      },
      {
        subcategory: "Адаптогены",
        items: ["Родиола", "Ашваганда"],
      },
    ],
  },
  {
    category: "Мужское здоровье",
    subcategories: [
      {
        subcategory: "Ферменты",
        items: ["Пищеварительные ферменты", "Бромелайн"],
      },
      {
        subcategory: "Пробиотики и пребиотики",
        items: ["Лактобактерии", "Бифидобактерии"],
      },
    ],
  },
  {
    category: "Женское здоровье",
    subcategories: [
      {
        subcategory: "Жиросжигатели",
        items: [],
      },
      {
        subcategory: "Блокаторы углеводов",
        items: [],
      },
      {
        subcategory: "Контроль аппетита",
        items: [],
      },
      {
        subcategory: "Комплексные программы для похудения",
        items: [],
      },
    ],
  },
  {
    category: "Диабет",
    subcategories: [
      {
        subcategory: "Рыбий жир и Омега-3",
        items: [],
      },
      {
        subcategory: "Коэнзим Q10",
        items: [],
      },
      {
        subcategory: "Красный дрожжевой рис",
        items: [],
      },
      {
        subcategory: "L-карнитин",
        items: [],
      },
    ],
  },
  {
    category: "Очищение; детокс; паразиты",
    subcategories: [
      {
        subcategory: "Коллаген",
        items: [],
      },
      {
        subcategory: "Биотин",
        items: [],
      },
      {
        subcategory: "Комплексы для кожи",
        items: [],
      },
      {
        subcategory: "Комплексы для волос и ногтей",
        items: [],
      },
    ],
  },
  {
    category: "Снижение веса",
    subcategories: [
      {
        subcategory: "Поддержка тестостерона",
        items: [],
      },
      {
        subcategory: "Здоровье простаты",
        items: [],
      },
      {
        subcategory: "Мужская энергия и выносливость",
        items: [],
      },
    ],
  },
  {
    category: "Нервная система",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Память; работа мозга",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Дыхательная система",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Зрение",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Сердце; сосуды",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Печень; желчный пузырь",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Пищеварение; обмен вещест",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Холестерин",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Выпадение волос",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Витаминные комплексы",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Расширение вен",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
  {
    category: "Дефицит железа; анемия",
    subcategories: [
      {
        subcategory: "Гормональный баланс",
        items: [],
      },
      {
        subcategory: "Поддержка менопаузы",
        items: [],
      },
      {
        subcategory: "Здоровье костей",
        items: [],
      },
    ],
  },
];
