import React from "react";
import { Grid } from "@mui/material";
import { CatalogProductCard } from "../CatalogProductCard";
import { useSelector } from "react-redux";
import { useProducts } from "../../shared/libs/requests";

import "./index.scss";

export const ProfileUserFavoriteSection = () => {
  const userStore = useSelector((state) => state.user.user);
  const {
    products: { products },
    loading,
    error,
  } = useProducts({ id: userStore?.metadata?.favoriteItems });

  const addToCartHandler = () => {};

  useProducts(userStore?.metadata?.favoriteItems);

  return (
    <div className="profile-user-favorite-container">
      {products && products.length > 0 && (
        <Grid container spacing={4}>
          {products.map((product) => (
            <CatalogProductCard
              key={product.id}
              product={product}
              addToCartHandler={addToCartHandler}
              xs={3}
            />
          ))}
        </Grid>
      )}
    </div>
  );
};
