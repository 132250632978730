// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-field {
  display: flex;
  flex: 1 1 auto;
  border: 1px solid black;
  border-radius: 50px;
  height: 34px;
  position: relative;
}
.search-field .MuiInputBase-root {
  margin-left: 14px;
}
.search-field .search-result {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 116px;
  max-height: 690px;
  width: 100%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1411764706);
  top: 50px;
  box-shadow: 0 10px 16px rgba(128, 128, 128, 0.3019607843);
  overflow: auto;
  z-index: 10;
  border-radius: 25px;
}
.search-field .search-result span {
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 500;
}
.search-field .search-result span:hover {
  background: #e3e3e3;
  cursor: pointer;
}
.search-field .search-result span:first-child {
  margin-top: 20px;
}
.search-field .search-result .empty-list {
  color: gray;
}
.search-field .search-result .empty-list:hover {
  background: none;
  cursor: default;
}
.search-field .search-popular-items {
  padding: 0 30px 30px;
}
.search-field .search-popular-items .title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  margin: 32px 0;
}`, "",{"version":3,"sources":["webpack://./src/features/HeaderSearchField/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,iBAAA;EACA,WAAA;EACA,iBAAA;EACA,6CAAA;EACA,SAAA;EACA,yDAAA;EACA,cAAA;EACA,WAAA;EACA,mBAAA;AAAJ;AACI;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;AACN;AAAM;EACE,mBAAA;EACA,eAAA;AAER;AACI;EACE,gBAAA;AACN;AACI;EACE,WAAA;AACN;AAAM;EACE,gBAAA;EACA,eAAA;AAER;AAGE;EACE,oBAAA;AADJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAAN","sourcesContent":[".search-field {\n  display: flex;\n  flex: 1 1 auto;\n  border: 1px solid black;\n  border-radius: 50px;\n  height: 34px;\n  position: relative;\n\n  & .MuiInputBase-root {\n    margin-left: 14px;\n  }\n\n  & .search-result {\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    min-height: 116px;\n    max-height: 690px;\n    width: 100%;\n    background: white;\n    border: 1px solid #00000024;\n    top: 50px;\n    box-shadow: 0 10px 16px rgba(128, 128, 128, 0.3019607843);\n    overflow: auto;\n    z-index: 10;\n    border-radius: 25px;\n    & span {\n      padding: 10px 30px;\n      font-size: 14px;\n      font-weight: 500;\n      &:hover {\n        background: #e3e3e3;\n        cursor: pointer;\n      }\n    }\n    & span:first-child {\n      margin-top: 20px;\n    }\n    & .empty-list {\n      color: gray;\n      &:hover {\n        background: none;\n        cursor: default;\n      }\n    }\n  }\n\n  & .search-popular-items {\n    padding: 0 30px 30px;\n    & .title {\n      font-size: 14px;\n      font-weight: 700;\n      line-height: 17px;\n      margin: 32px 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
