import React from "react";
import { RoundedButton } from "../../shared/RoundedButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../app/userSlice";
import axios from "axios";

import "./index.scss";

export const CatalogProductCard = ({ product, xs, addToCartHandler }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user.user);
  const onProductNavigate = () => navigate(`/product/${product.id}`);

  const addFavouriteHandler = async () => {
    if (!userStore?.id) {
      alert("Необходимо авторизоваться");
      // TODO: добавить открытие модального окна для авторизации
      return;
    }

    const token = localStorage.getItem("token");

    const updatedFavList = userStore?.metadata?.favoriteItems.includes(product?.id)
      ? userStore.metadata.favoriteItems.filter((el) => el !== product?.id)
      : [...userStore.metadata.favoriteItems, product?.id];

    axios
      .post(
        `${process.env.REACT_APP_MEDUSA_BACKEND_URL}/store/customers/me`,
        {
          metadata: {
            favoriteItems: updatedFavList,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        dispatch(setUser(response?.data?.customer));
        localStorage.setItem("user", JSON.stringify(response?.data?.customer));
      })
      .catch((error) => {
        console.error("Ошибка при получении данных клиента:", error);
      });
  };

  return (
    <>
      <Grid item xs={xs} spacing={4} className="catalog-product-card">
        {!userStore?.metadata?.favoriteItems?.includes(product?.id) ? (
          <FavoriteBorder
            className="add-to-favorite-btn"
            onClick={addFavouriteHandler}
          />
        ) : (
          <Favorite
            className="add-to-favorite-btn"
            onClick={addFavouriteHandler}
          />
        )}
        <div
          className="catalog-product-card__image-container"
          onClick={onProductNavigate}
        >
          <span
            style={{ backgroundImage: `url(${product?.images?.[0]?.url})` }}
          />
        </div>
        <p
          className="catalog-product-card__section"
          onClick={onProductNavigate}
        >
          {product?.description}
        </p>
        <p className="catalog-product-card__title" onClick={onProductNavigate}>
          {product?.title}
        </p>
        <div className="catalog-product-card__button-box">
          <RoundedButton onClick={() => addToCartHandler(product)}>
            <ShoppingCartIcon />
            <li key={product?.variant?.[0]?.id}>
              {(product?.variants?.[0]?.prices[0]?.amount / 100).toFixed(0)} ₽
            </li>
            {/*{product.variants.map((variant) => (*/}
            {/*  <li key={variant.id}>*/}
            {/*    {(variant?.prices[0]?.amount / 100).toFixed(0)} ₽*/}
            {/*  </li>*/}
            {/*))}*/}
          </RoundedButton>
        </div>
      </Grid>
    </>
  );
};
