import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { setShowAuthForm } from "../../app/userSlice";
import { useDispatch, useSelector } from "react-redux";

import "./index.scss";

const DiscountModalForm = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userStore = useSelector((state) => state.user.user);

  const handleClose = () => setOpen(false);

  const openAuthModal = () => dispatch(setShowAuthForm("login"));

  useEffect(() => {
    if (userStore && !userStore?.id) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 5000); // 5 секунд
      return () => clearTimeout(timer);
    }
  }, [userStore?.id]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-box">
        <div className="modal-content">
          <Typography variant="h6" className="modal-text">
            Вам здорово повезло! Забирайте скидочную карту 5% при регистрации на
            Zenhealth!
          </Typography>
          <Typography variant="h2" className="modal-percentage">
            5%
          </Typography>
          <Close className="close-btn" onClick={handleClose} />
        </div>
        <Button
          variant="contained"
          className="modal-button"
          onClick={openAuthModal}
        >
          Зарегистрироваться →
        </Button>
      </Box>
    </Modal>
  );
};

export default DiscountModalForm;
