import React from "react";

import "./index.scss";

export const PdfReadersPage = ({ fileName }) => (
  <div className="pdf-container">
    <iframe src={`/media/${fileName}.pdf`} width="100%" height="100%">
      Этот браузер не поддерживает встраивание PDF. Вы можете <a href={`/media/${fileName}.pdf`}>скачать файл</a>.
    </iframe>
  </div>
);
