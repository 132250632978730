// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-form {
  margin: 40px auto 30px auto !important;
  position: relative;
}
.catalog-form .padding-container {
  padding: 0 10px;
}
.catalog-form .catalog-product-card__button-up {
  position: sticky;
  display: flex;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  width: max-content;
  align-self: end;
}
.catalog-form .empty-product-list {
  font-weight: 500;
  margin: 50px 0 0 10px;
  margin: 100px auto 50vh;
  font-size: 24px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/widgets/CatalogForm/index.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,gBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AAAJ;AAGE;EACE,gBAAA;EACA,qBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;AADJ","sourcesContent":[".catalog-form {\n  margin: 40px auto 30px auto !important;\n  position: relative;\n\n  .padding-container {\n    padding: 0 10px;\n  }\n\n  & .catalog-product-card__button-up {\n    position: sticky;\n    display: flex;\n    bottom: 20px;\n    right: 20px;\n    z-index: 10;\n    width: max-content;\n    align-self: end;\n  }\n\n  & .empty-product-list {\n    font-weight: 500;\n    margin: 50px 0 0 10px;\n    margin: 100px auto 50vh;\n    font-size: 24px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
