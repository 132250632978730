import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import { RoundedButton } from "../../shared/RoundedButton";
import { useNavigate } from "react-router-dom";

import "./index.scss";

export const CartModalForm = ({ showCartPanel, setShowCartPanel }) => {
  const navigate = useNavigate();
  const toggleDrawer = (newOpen) => () => setShowCartPanel(newOpen);

  const showMoreItemsHandler = () => {};

  const makeOrderHandler = () => {
    navigate(`/placing-order/123232xasdw2`);
  };

  return (
    <Drawer
      open={showCartPanel}
      anchor="right"
      className="cart-drawer"
      onClose={toggleDrawer("")}
    >
      <Box role="presentation" className="cart-drawer-container">
        <Typography className="cart-title">Корзина</Typography>
        <div className="cart-item-list"></div>
        <Typography className="cart__show-more" onClick={showMoreItemsHandler}>
          Еще товары в корзине ↓
        </Typography>

        <TextField
          id="outlined-basic"
          label="Введите промокод"
          variant="outlined"
          size="small"
        />

        <div className="card-total-cost">
          <Typography fontSize="30px" fontWeight="600" marginBottom="10px">
            Сумма заказа
          </Typography>
          <div className="cost-container">
            <Typography fontSize="14px" fontWeight="600">
              Стоимость продуктов
            </Typography>
            <Typography fontSize="14px" fontWeight="600">
              100240 р
            </Typography>
          </div>
          <hr />
          <div className="cost-container">
            <Typography fontSize="14px" fontWeight="600">
              Итог
            </Typography>
            <Typography fontSize="30px" fontWeight="600">
              100240 р
            </Typography>
          </div>
          <RoundedButton className="order-btn" onClick={makeOrderHandler}>
            Оформить заказ
          </RoundedButton>
        </div>

        <div className="cart-popular-items">
          <Typography fontSize="30px" fontWeight="600">
            Вам может понравиться
          </Typography>
        </div>
      </Box>
    </Drawer>
  );
};
