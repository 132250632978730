import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Grid, Tab, Tabs } from "@mui/material";
import userPhoto from "../../media/user_avatar.png";
import userDiscountPhoto from "../../media/user_discount.png";
import { ProfileUserInfoSection } from "../../features/ProfileUserInfoSection";
import { ProfileUserCardsSection } from "../../features/ProfileUserCardsSection";
import { ProfileUserFAQSection } from "../../features/ProfileUserFAQSection";
import { ProfileUserOrdersSection } from "../../features/ProfileUserOrdersSection";
import { RoundedButton } from "../../shared/RoundedButton";
import { ProfileUserFavoriteSection } from "../../features/ProfileUserFavoriteection";
import { ProfileUserAddressSection } from "../../features/ProfileUserAddressSection";
import { useSelector } from "react-redux";
import { useProducts } from "../../shared/libs/requests/product";
import { MinimalisticProductCard } from "../../features/MinimalisticProductCard";
import { CardSkeletonForm } from "../../features/CardSkeletonForm";

import "./index.scss";

export const ProfileForm = () => {
  const { tabId } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(parseInt(tabId, 10) || 0);
  const userStore = useSelector((state) => state.user.user);
  const {
    products: { products },
    loading,
    refetch,
  } = useProducts({
    fields: `*variants.calculated_price`,
    country_code: "RU",
    limit: 4,
  });

  useEffect(() => {
    if (tabId !== undefined) {
      setSelectedTab(parseInt(tabId, 10));
    }
  }, [tabId]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    navigate(`/profile/${newValue}`);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const getUserName = () => {
    const firstName = userStore?.first_name ?? "";
    const lastName = userStore?.last_name ?? "";

    if (firstName || lastName) {
      return `${firstName} ${lastName}`.trim();
    }

    return "Пользователь";
  };

  return (
    <div className="profile-form main-container">
      <h1 className="profile-form__tab-title">Профиль</h1>
      <div className="user-info">
        <img className="user-info__avatar" src={userPhoto} alt="" />
        <div className="user-info__info-container">
          <h4 className="user-info__name">{getUserName()}</h4>
          <div className="user-info__details-container">
            <div className="user-info__details-container column">
              <span className="user-info__tab-title">Скидка</span>
              <span className="user-info__tab-value">5%</span>
            </div>
            <div className="user-info__details-container column" />
            <div className="user-info__details-container column">
              <span className="user-info__tab-title">Бонусы</span>
              <span className="user-info__tab-value">0</span>
            </div>
          </div>
        </div>
        <div className="user-info__discount-container">
          <img
            className="user-info__discount-photo"
            src={userDiscountPhoto}
            alt="user-photo"
          />
          <span>скидка в 5%</span>
        </div>
      </div>
      <div className="details-table">
        <div className="details-table__content">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Личная информация" {...a11yProps(0)} />
              <Tab label="Избранное" {...a11yProps(1)} />
              <Tab label="Мои покупки" {...a11yProps(2)} />
              <Tab label="Мои карты" {...a11yProps(3)} />
              <Tab label="Мои адреса" {...a11yProps(4)} />
              {/*<Tab label="Уведомления" {...a11yProps(5)} />*/}
            </Tabs>
          </Box>
          <CustomTabPanel value={selectedTab} index={0}>
            <ProfileUserInfoSection />
          </CustomTabPanel>
          <CustomTabPanel value={selectedTab} index={1}>
            <ProfileUserFavoriteSection />
          </CustomTabPanel>
          <CustomTabPanel value={selectedTab} index={2}>
            <ProfileUserOrdersSection />
          </CustomTabPanel>
          <CustomTabPanel value={selectedTab} index={3}>
            <ProfileUserCardsSection />
          </CustomTabPanel>
          <CustomTabPanel value={selectedTab} index={4}>
            <ProfileUserAddressSection />
          </CustomTabPanel>
          {/*<CustomTabPanel value={selectedTab} index={5}>*/}
          {/*  <ProfileUserNotificationOptionsSection />*/}
          {/*</CustomTabPanel>*/}
          <CustomTabPanel value={selectedTab} index={5}>
            <ProfileUserFAQSection />
          </CustomTabPanel>
        </div>
        <div className="details-table__product-sidebar">
          <h4 className="product-sidebar__title">Популярные товары</h4>
          <Grid
            container
            spacing={4}
            direction="column"
            margin="0"
            marginBottom="20px"
          >
            {loading ? (
              <CardSkeletonForm cardsCount={4} xsCount={12} isMinimalistic />
            ) : (
              products &&
              products.length > 0 &&
              products.map((product) => (
                <MinimalisticProductCard
                  key={product.id}
                  product={product}
                  xs={1}
                />
              ))
            )}
          </Grid>
          <RoundedButton
            className="faq-button"
            onClick={(e) => handleChange(e, 5)}
          >
            Частые вопрос / ответы
          </RoundedButton>
        </div>
      </div>
    </div>
  );
};
