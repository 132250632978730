
export const setLocalItem = (fieldName, value) => {
  try {
    localStorage.setItem(fieldName, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
}

export const getLocalItem = (fieldName) => {
  try {
    const data = localStorage.getItem(fieldName);

    return data ? JSON.parse(data) : null;
  } catch (e) {
    console.error(e);
  }
}

export const removeLocalItem = (fieldName) => {
  try {
    localStorage.removeItem(fieldName)
  } catch (e) {
    console.error(e);
  }
}