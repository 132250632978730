export const IconVK = () => (
  <svg
    width="18.000000"
    height="10.000000"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      id="Vector"
      d="M17.26 9.99L15.3 9.99C14.55 9.99 14.33 9.43 12.99 8.19C11.82 7.13 11.32 7 11.03 7C10.63 7 10.52 7.1 10.52 7.63L10.52 9.28C10.52 9.73 10.36 9.99 9.11 9.99C7.02 9.99 4.73 8.81 3.1 6.62C0.66 3.42 0 1 0 0.52C0 0.24 0.11 0 0.67 0L2.64 0C3.14 0 3.33 0.2 3.52 0.71C4.48 3.33 6.11 5.63 6.78 5.63C7.02 5.63 7.14 5.52 7.14 4.93L7.14 2.21C7.07 0.97 6.36 0.86 6.36 0.42C6.36 0.21 6.54 0 6.86 0L9.95 0C10.37 0 10.52 0.2 10.52 0.67L10.52 4.32C10.52 4.71 10.7 4.85 10.82 4.85C11.07 4.85 11.28 4.71 11.74 4.28C13.16 2.8 14.16 0.52 14.16 0.52C14.29 0.24 14.52 0 15.02 0L16.99 0C17.58 0 17.71 0.28 17.58 0.67C17.33 1.75 14.93 4.91 14.93 4.91C14.72 5.22 14.64 5.37 14.93 5.73C15.14 6 15.83 6.55 16.29 7.05C17.13 7.94 17.77 8.69 17.95 9.21C18.11 9.73 17.83 10 17.27 10L17.26 9.99Z"
      fill="#808080"
      fillOpacity="1.000000"
      fillRule="nonzero"
    />
  </svg>
);
