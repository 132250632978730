import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { RoundedButton } from "../../shared/RoundedButton";

import "./index.scss";

export const ProfileUserAddressSection = () => {
  const [selectedAddress, setSelectedAddress] = useState(0);

  const onChangeAddress = (id) => setSelectedAddress(id);
  const getActiveAddressClass = (id) => (selectedAddress === id ? "active" : "");

  return (
    <div className="profile-user-address-container">
      <div className="address-block">
        <div className={`address-container ${getActiveAddressClass(0)}`}>
          <Typography className="address-name">
            г. Москва, ул. Московская, д. 6, кв. 750
          </Typography>
          <div className="address-action-container">
            <RoundedButton className="address-action-btn" onClick={() => onChangeAddress(0)}>
              Выбрать
            </RoundedButton>
            <RoundedButton className="address-action-btn">Удалить</RoundedButton>
          </div>
        </div>
        <div className={`address-container ${getActiveAddressClass(1)}`}>
          <Typography className="address-name">
            г. Москва, ул. Московская, д. 6, кв. 750
          </Typography>
          <div className="address-action-container">
            <RoundedButton className="address-action-btn" onClick={() => onChangeAddress(1)}>
              Выбрать
            </RoundedButton>
            <RoundedButton className="address-action-btn">Удалить</RoundedButton>
          </div>
        </div>
        <div className={`address-container ${getActiveAddressClass(2)}`}>
          <Typography className="address-name">
            г. Москва, ул. Московская, д. 6, кв. 750
          </Typography>
          <div className="address-action-container">
            <RoundedButton className="address-action-btn" onClick={() => onChangeAddress(2)}>
              Выбрать
            </RoundedButton>
            <RoundedButton className="address-action-btn">Удалить</RoundedButton>
          </div>
        </div>
      </div>
      <RoundedButton>+ Добавить адрес</RoundedButton>
    </div>
  );
};
