// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-filter {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  overflow-y: auto;
}
.category-filter span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
  font-weight: 400;
  text-transform: capitalize;
}
.category-filter .MuiAccordionDetails-root {
  padding: 4px 16px 8px;
}
.category-filter .MuiTypography-root {
  font-weight: 600;
  text-transform: uppercase;
}
.category-filter .MuiAccordion-root {
  box-shadow: none;
}
.category-filter::-webkit-scrollbar {
  padding: 10px;
  width: 12px;
}
.category-filter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  height: 100px;
}
.category-filter::-webkit-scrollbar-thumb {
  border: 4.1px solid #fcfcfc;
  border-radius: 13px;
  background-color: #c4c4c4;
}`, "",{"version":3,"sources":["webpack://./src/features/CatalogCategoryFilter/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,gBAAA;EACA,0BAAA;AACJ;AAEE;EACE,qBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,yBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,aAAA;EACA,WAAA;AAHJ;AAKE;EACE,0CAAA;EACA,aAAA;AAHJ;AAKE;EACE,2BAAA;EACA,mBAAA;EACA,yBAAA;AAHJ","sourcesContent":[".category-filter {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  gap: 30px;\n  height: 100%;\n  overflow-y: auto;\n\n  & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {\n    font-weight: 400;\n    text-transform: capitalize;\n  }\n\n  & .MuiAccordionDetails-root {\n    padding: 4px 16px 8px;\n  }\n\n  & .MuiTypography-root {\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n\n  & .MuiAccordion-root {\n    box-shadow: none;\n  }\n\n  &::-webkit-scrollbar {\n    padding: 10px;\n    width: 12px;\n  }\n  &::-webkit-scrollbar-track {\n    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);\n    height: 100px;\n  }\n  &::-webkit-scrollbar-thumb {\n    border: 4.1px solid #fcfcfc;\n    border-radius: 13px;\n    background-color: #c4c4c4;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
