import React from "react";
import { IconNotFoundPage } from "../../shared/IconNotFoundPage";
import Typography from "@mui/material/Typography";
import { RoundedButton } from "../../shared/RoundedButton";
import { useNavigate } from "react-router-dom";

import "./index.scss";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const onRootPageNavigate = () => navigate(`/`);

  return (
    <div className="not-found-page">
      <Typography variant="h5" fontWeight="600">
        К сожалению такой страницы не существует.
      </Typography>
      <IconNotFoundPage />
      <Typography variant="secondary" fontWeight="600">
        Ошибка №404.
      </Typography>
      <RoundedButton onClick={onRootPageNavigate}>Вернуться на главную</RoundedButton>
    </div>
  );
};
