// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-user-notification-option-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 164px;
  border: 1px solid rgba(128, 128, 128, 0.51);
  border-radius: 20px;
  padding: 20px;
  margin-top: 44px;
}
.profile-user-notification-option-container .tab-title {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.profile-user-notification-option-container .MuiFormControlLabel-label {
  text-transform: uppercase;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/features/ProfileUserNotificationOptionsSection/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,oBAAA;EACA,2CAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;AACJ;AAEE;EACE,yBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".profile-user-notification-option-container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  margin-bottom: 164px;\n  border: 1px solid rgba(128, 128, 128, 0.51);\n  border-radius: 20px;\n  padding: 20px;\n  margin-top: 44px;\n\n  & .tab-title {\n    font-size: 14px;\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n\n  & .MuiFormControlLabel-label {\n    text-transform: uppercase;\n    font-weight: 600;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
