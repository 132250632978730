import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, InputBase } from "@mui/material";
import Medusa from "@medusajs/medusa-js";
import { MinimalisticProductCard } from "../MinimalisticProductCard";
import { useProducts } from "medusa-react";
import { useNavigate } from "react-router-dom";
import { CardSkeletonForm } from "../CardSkeletonForm";

import "./index.scss";

export const HeaderSearchField = () => {
  const navigate = useNavigate();
  const medusa = new Medusa({
    baseUrl: process.env.REACT_APP_MEDUSA_BACKEND_URL,
    maxRetries: 3,
  });
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const { products, isLoading } = useProducts({ limit: 4 });

  const onChangeInputHandler = (value) => {
    setSearchValue(value);
    medusa.products
      .search({
        q: value,
      })
      .then(({ hits }) => {
        setFilteredList(hits);
      })
      .catch((error) => {
        console.error("Search Error:", error);
      });
  };

  const onCloseSearchPopup = () => {
    setSearchValue("");
    setFilteredList([]);
  };

  const onProductNavigate = (product) => {
    onCloseSearchPopup();
    navigate(`/product/${product.id}`);
  };

  return (
    <div className="search-field">
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Поиск"
        value={searchValue}
        inputProps={{ "aria-label": "Поиск" }}
        onChange={(e) => onChangeInputHandler(e.target.value)}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon sx={{ color: "black" }} />
      </IconButton>
      {searchValue ? (
        <div className="search-result">
          {filteredList.length ? (
            filteredList?.map((item, index) =>
              index < 10 ? (
                <span key={item.title} onClick={() => onProductNavigate(item)}>
                  {item.title}
                </span>
              ) : null,
            )
          ) : (
            <span className="empty-list">нет результатов...</span>
          )}
          <div className="search-popular-items">
            <p className="title">Популярные товары</p>
            {isLoading ? (
              <CardSkeletonForm cardsCount={4} xsCount={6} isMinimalistic />
            ) : products && products.length > 0 ? (
              <Grid container spacing={4}>
                {products &&
                  products.length > 0 &&
                  products.map((product) => (
                    <MinimalisticProductCard
                      key={product.id}
                      product={product}
                      xs={6}
                      onCloseSearch={onCloseSearchPopup}
                    />
                  ))}
              </Grid>
            ) : (
              <span>No Products</span>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
