// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-user-address-container {
  max-width: 100%;
  margin-bottom: 164px;
  margin-top: 50px;
}
.profile-user-address-container .address-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}
.profile-user-address-container .address-block .address-container {
  box-sizing: border-box;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 25px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(255, 255, 255);
}
.profile-user-address-container .address-block .address-container .address-name {
  font-size: 30px;
  font-weight: 600;
  line-height: 37px;
}
.profile-user-address-container .address-block .address-container .address-action-container {
  display: flex;
  gap: 15px;
}
.profile-user-address-container .address-block .address-container.active {
  box-sizing: border-box;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 25px;
  background: rgb(0, 0, 0);
}
.profile-user-address-container .address-block .address-container.active .address-name {
  color: white;
}
.profile-user-address-container .address-block .address-container.active .address-action-container .address-action-btn {
  border-color: white !important;
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/features/ProfileUserAddressSection/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,oBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AACJ;AACI;EACE,sBAAA;EACA,oCAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EAEA,8BAAA;AAAN;AAEM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAAR;AAGM;EACE,aAAA;EACA,SAAA;AADR;AAKI;EACE,sBAAA;EACA,oCAAA;EACA,mBAAA;EAEA,wBAAA;AAJN;AAMM;EACE,YAAA;AAJR;AAQQ;EACE,8BAAA;EACA,uBAAA;AANV","sourcesContent":[".profile-user-address-container {\n  max-width: 100%;\n  margin-bottom: 164px;\n  margin-top: 50px;\n\n  & .address-block {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    margin-bottom: 20px;\n\n    & .address-container {\n      box-sizing: border-box;\n      border: 1px solid rgb(204, 204, 204);\n      border-radius: 25px;\n      padding: 20px 30px;\n      display: flex;\n      flex-direction: column;\n      gap: 16px;\n\n      background: rgb(255, 255, 255);\n\n      & .address-name {\n        font-size: 30px;\n        font-weight: 600;\n        line-height: 37px;\n      }\n\n      & .address-action-container {\n        display: flex;\n        gap: 15px;\n      }\n    }\n\n    & .address-container.active {\n      box-sizing: border-box;\n      border: 1px solid rgb(204, 204, 204);\n      border-radius: 25px;\n\n      background: rgb(0, 0, 0);\n\n      & .address-name {\n        color: white;\n      }\n\n      & .address-action-container {\n        & .address-action-btn {\n          border-color: white!important;\n          color: white!important;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
