import React from "react";
import { HeaderPanel } from "../../widgets/HeaderPanel";
import { FooterPanel } from "../../widgets/FooterPanel";

import "./index.scss";

export const DefaultPageContainer = ({ children, title = null }) => (
  <div className="main-page">
    <HeaderPanel title={title} />
    {children}
    <FooterPanel />
  </div>
);
