import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarProvider, useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import { ShoppingCart } from "@mui/icons-material";

import "./index.scss";

function CartPopup({ triggerSnackbar, product }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (triggerSnackbar) {
      enqueueSnackbar(
        <div className="snackbar-container">
          <img
            className="snackbar-image"
            src={product?.images?.[0]?.url}
            alt="item"
          />
          <div className="snackbar-content">
            <Typography className="snackbar-content__status">
              <ShoppingCart />
              ДОБАВЛЕН В КОРЗИНУ
            </Typography>

            <Typography className="snackbar-content__item-name">
              {product?.title}
            </Typography>

            <Typography className="snackbar-content__item-count">
              1 шт.
            </Typography>
          </div>
        </div>,
        {
          variant: "default",
          autoHideDuration: 6000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
          action: (key) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        },
      );
    }
  }, [triggerSnackbar, enqueueSnackbar, closeSnackbar]);

  return null;
}

export default CartPopup;

export const AddItemToCartPopup = ({ triggerSnackbar, product }) => {
  return (
    <SnackbarProvider maxSnack={5}>
      <CartPopup triggerSnackbar={triggerSnackbar} product={product} />
    </SnackbarProvider>
  );
};
