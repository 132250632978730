import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { RoundedButton } from "../../shared/RoundedButton";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import { openAuthFormForUnauthorizedActions } from "../../shared/libs/navigate";

export const HeaderFavoriteForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user.user);
  const onFavoriteListNavigate = () =>
    openAuthFormForUnauthorizedActions(
      userStore?.id,
      () => navigate("/profile/1"),
      dispatch,
    );

  const favoriteItemLength = userStore?.metadata?.favoriteItems?.length;

  return (
    <div className="header-catalog-form">
      {favoriteItemLength ? (
        <Badge badgeContent={favoriteItemLength} color="primary">
          <RoundedButton onClick={onFavoriteListNavigate}>
            <FavoriteBorderIcon />
          </RoundedButton>
        </Badge>
      ) : (
        <RoundedButton onClick={onFavoriteListNavigate}>
          <FavoriteBorderIcon />
        </RoundedButton>
      )}
    </div>
  );
};
