import React, { useState, useEffect } from 'react';
import { Card, Button, Typography, CircularProgress } from '@mui/material';
import { RoundedButton } from '../../shared/RoundedButton';
import { getOrdersList } from '../../shared/libs/requests/order';
import dayjs from 'dayjs';
import { IconContainer } from '../../shared/IconContainer';
import { IconSuccess } from '../../shared/IconSuccess';
import { IconFulfill } from '../../shared/IconFulfill';
import { IconShipping } from '../../shared/IconShipping';
import { formatPrice } from '../../shared/libs/helpers';
import { MinimalisticProductCard } from '../MinimalisticProductCard';

import './index.scss';

export const ProfileUserOrdersSection = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  const orderStatus = {
    not_fulfilled: {
      status: 'not_fulfilled',
      orderStatusIcon: <IconSuccess />,
      description: 'Ваш заказ был принят, но его сборка еще не началась.',
      text: 'Заказ принят',
    },
    fulfilled: {
      status: 'fulfilled',
      orderStatusIcon: <IconFulfill />,
      description: 'Ваш заказ собран и готов к отправке.',
      text: 'Заказ собран',
    },
    shipped: {
      status: 'shipped',
      orderStatusIcon: <IconShipping />,
      description: 'Ваш заказ отправлен и сейчас находится в пути к вам.',
      text: 'Заказ в пути',
    },
    delivered: {
      status: 'delivered',
      orderStatusIcon: <IconContainer />,
      description: 'Ваш заказ доставлен и готов к получению.',
      text: 'Заказ приехал',
    },
    canceled: {
      status: 'canceled',
      orderStatusIcon: <IconContainer />,
      description: 'Ваш заказ был отменен. Обратитесь в поддержку, если есть вопросы.',
      text: 'Заказ отменен',
    },
  };

  const ordersList = {
    orders: [
      {
        id: 'order_01',
        fulfillment_status: 'delivered', // Статус заказа (например, "completed", "pending", "canceled")
        payment_status: 'paid', // Статус оплаты ("paid", "not_paid")
        total: 1500, // Общая стоимость заказа в минимальных единицах валюты (например, рубли, копейки)
        subtotal: 1400, // Сумма без учета скидок, налогов и доставки
        tax_total: 100, // Сумма налогов
        discount_total: 0, // Скидки
        created_at: '2024-01-01T12:00:00Z', // Дата и время создания заказа
        updated_at: '2024-01-01T13:00:00Z', // Дата последнего обновления
        items: [
          {
            id: 'item_01',
            title: 'Футболка',
            quantity: 2,
            unit_price: 700, // Цена за единицу товара
            subtotal: 1400, // Сумма для этого товара (количество * цена)
          },
        ],
        shipping_address: {
          address_1: 'улица Ленина, 1',
          city: 'Москва',
          postal_code: '101000',
          country_code: 'RU', // Код страны (например, "RU" для России)
        },
        shipping_methods: [
          {
            id: 'ship_01',
            name: 'Стандартная доставка',
            price: 100, // Стоимость доставки
            status: 'shipped', // Статус доставки ("pending", "shipped")
          },
        ],
        customer: {
          id: 'customer_01',
          first_name: 'Иван',
          last_name: 'Иванов',
          email: 'ivanov@example.com',
          phone: '+7 (999) 123-45-67',
        },
      },
      {
        id: 'order_02',
        status: 'pending',
        payment_status: 'not_paid',
        total: 2300,
        subtotal: 2200,
        tax_total: 100,
        discount_total: 0,
        created_at: '2024-02-01T12:00:00Z',
        updated_at: '2024-02-01T12:30:00Z',
        items: [
          {
            id: 'item_02',
            title: 'Кроссовки',
            quantity: 1,
            unit_price: 2200,
            subtotal: 2200,
          },
        ],
        shipping_address: {
          address_1: 'площадь Победы, 10',
          city: 'Санкт-Петербург',
          postal_code: '190000',
          country_code: 'RU',
        },
        shipping_methods: [
          {
            id: 'ship_02',
            name: 'Экспресс доставка',
            price: 100,
            status: 'pending',
          },
        ],
        customer: {
          id: 'customer_02',
          first_name: 'Алексей',
          last_name: 'Петров',
          email: 'petrov@example.com',
          phone: '+7 (999) 987-65-43',
        },
      },
    ],
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const ordersData = await getOrdersList();
        // setOrders(ordersData);
        setOrders(ordersList);
      } catch (error) {
        console.error('Ошибка получения заказов:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!orders?.length) {
      fetchOrders();
    }
  }, []);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
  };

  const handleBackClick = () => {
    setSelectedOrder(null);
  };

  const getDeliveryDate = (date) => dayjs(date).format('DD.MM');

  const getOrderCreatedDate = (date) => dayjs(date).format('DD.MM, HH:mm');

  if (loading) {
    return (
      <div className="circular-loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="profile-user-order-container">
      {!selectedOrder ? (
        <div className="order-list">
          {orders?.orders?.length === 0 ? (
            <Typography className="empty-orders-list">Заказов пока нет</Typography>
          ) : (
            orders?.orders?.map((order) => (
              <>
                <Card key={order?.id} onClick={() => handleOrderClick(order)} className="order-card">
                  <div className="order-card__box order-date">
                    <Typography variant="body2" className="order-date__text">
                      {getDeliveryDate(order?.fulfillments?.delivered_at)}
                    </Typography>
                    <span className="order-type online">Онлайн-покупка</span>
                  </div>

                  <div className="order-card__box">
                    <span className="order-id">№ {order?.id}</span>
                    <div className="status-container">
                      <span className="status-icon">{orderStatus[order?.fulfillment_status]?.orderStatusIcon}</span>
                      <span className="status-text">{orderStatus[order?.fulfillment_status]?.text}</span>
                    </div>
                  </div>

                  <div className="order-card__box">
                    <Typography className="order-title">Состав / {order?.items?.length} шт.</Typography>
                    <div className="order-icons-container">
                      {order?.items?.map((el) => (
                        <MinimalisticProductCard key={el.id} product={el} onlyImage xs={6} />
                      ))}
                    </div>
                  </div>

                  <div className="order-card__box">
                    <Typography className="order-title">Сумма</Typography>
                    <Typography className="order-total">{formatPrice(order?.total)}</Typography>
                    <Typography className="order-title">Подробнее о заказе →</Typography>
                  </div>
                </Card>
              </>
            ))
          )}
        </div>
      ) : (
        <div className="order-details">
          <Button className="return-button" onClick={handleBackClick}>
            ← Мои покупки
          </Button>
          <div className="order-container">
            <div className="order-column">
              <div className="order-status">
                <span className="order-type online">Онлайн-покупка</span>
                <span>№ {selectedOrder?.id}</span>
              </div>
              <div className="order-info-container">
                <div className="order-info-order">
                  <Typography className="order-info-title">Информация о заказе</Typography>
                  <div className="info-row">
                    <span>Дата покупки</span>
                    <span>{getOrderCreatedDate(selectedOrder?.created_at)}</span>
                  </div>
                  <div className="info-row">
                    <span>Способ оплаты</span>
                    <span>Картой</span>
                  </div>
                  <div className="info-row">
                    <span>Адрес магазина</span>
                    <span>Онлайн-магазин</span>
                  </div>
                </div>
              </div>
              <div>
                <Typography className="order-info-title">Состав заказа / 2 шт.</Typography>
              </div>
            </div>

            <div className="order-column">
              <div className="total-container">
                <div className="total-info-order">
                  <div className="info-row">
                    <span>Стоимость</span>
                    <span>{formatPrice(selectedOrder?.subtotal)}</span>
                  </div>
                  <div className="info-row">
                    <span>Доставка</span>
                    <span>{formatPrice(selectedOrder?.shipping_methods?.price)}</span>
                  </div>
                  <div className="info-row">
                    <span>Скидка</span>
                    <span className="order-discount">-{formatPrice(selectedOrder?.discount_total)}</span>
                  </div>
                </div>

                <div className="info-row without-border">
                  <span>Итого</span>
                  <span className="order-total">{formatPrice(selectedOrder?.total)}</span>
                </div>

                <div className="total-order-status">
                  <Typography className="total-order-status__title">{orderStatus[selectedOrder?.fulfillment_status]?.text}</Typography>
                  <Typography className="total-order-status__description">
                    {orderStatus[selectedOrder?.fulfillment_status]?.description}
                  </Typography>
                </div>

                <div className="total-order-status-icons">
                  {Object.values(orderStatus)
                    .filter((el) => el.status !== 'canceled')
                    .map((el) => (
                      <span className={selectedOrder?.fulfillment_status === el.status ? 'active' : null}>{el.orderStatusIcon}</span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileUserOrdersSection;
