import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { CommonButton } from "../../shared/CommonButton";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { RoundedButton } from "../../shared/RoundedButton";
import { Autocomplete, Stack, TextField } from "@mui/material";
import debounce from "lodash.debounce";

import "./index.scss";

export const HeaderRegionsForm = () => {
  const viewportHeight = window.innerHeight;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [citiesList, setCitiesList] = useState([]);
  const [streetsList, setStreetsList] = useState([]);
  const [cityId, setCityId] = useState("");

  const toggleDrawer = useCallback(
    (newOpen) => () => {
      setOpenDrawer(newOpen);
      if (!newOpen) {
        setCityId("");
        setCitiesList([]);
        setStreetsList([]);
      }
    },
    [],
  );

  const onApplyFilterBtnHandler = useCallback(() => {
    // Здесь логика обработки нажатия кнопки "Привезти сюда"
  }, []);

  const fetchCities = async (locationValue) => {
    const url = `https://catalog.api.2gis.com/2.0/region/search?q=${locationValue}&key=5df490c3-58d9-4fff-953b-96e5851b97fa`;
    let response = await fetch(url);
    let commits = await response.json();
    const items = commits?.result?.items || [];
    setCitiesList(items);
    if (items.length) {
      setCityId(items[0]?.id);
    }
  };

  const fetchStreets = async (locationValue) => {
    const url = `https://catalog.api.2gis.com/3.0/suggests?q=${locationValue}&region_id=${cityId}&key=5df490c3-58d9-4fff-953b-96e5851b97fa&suggest_type=address&fields=items.point`;
    let response = await fetch(url);
    let commits = await response.json();
    setStreetsList(commits?.result?.items || []);
  };

  // Дебаунс для ввода города
  const debouncedFetchCities = useCallback(
    debounce((locationValue) => {
      fetchCities(locationValue);
    }, 300),
    [],
  );

  // Дебаунс для ввода улицы
  const debouncedFetchStreets = useCallback(
    debounce((locationValue) => {
      fetchStreets(locationValue);
    }, 300),
    [cityId],
  );

  return (
    <div>
      <div className="regions-form">
        <RoundedButton
          className="regions-form__btn"
          onClick={toggleDrawer(true)}
        >
          <PlaceOutlinedIcon />
          Адрес
        </RoundedButton>
        <Drawer
          open={openDrawer}
          className="regions-drawer"
          onClose={toggleDrawer(false)}
        >
          <Box
            style={{ height: `${viewportHeight - 48}px` }}
            role="presentation"
            className="regions-drawer-container"
          >
            <div className="regions-drawer__title-box">
              <span className="main-title">Адрес доставки</span>
              <Button onClick={toggleDrawer(false)}>
                <CloseIcon />
              </Button>
            </div>
            <div className="regions-drawer__forms">
              <Stack spacing={4}>
                <Autocomplete
                  id="city"
                  disableClearable
                  onInputChange={(e, value) => debouncedFetchCities(value)}
                  noOptionsText="пусто"
                  options={citiesList.map((option) => option?.name || "")}
                  renderInput={(params) => (
                    <TextField {...params} label="Город" variant="standard" />
                  )}
                />
                <Autocomplete
                  id="street"
                  disableClearable
                  disabled={!cityId}
                  noOptionsText="пусто"
                  onInputChange={(e, value) => debouncedFetchStreets(value)}
                  options={streetsList.map((option) => option?.full_name || "")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Улица и дом"
                      variant="standard"
                    />
                  )}
                />
              </Stack>
            </div>
            <CommonButton
              label="Привезти сюда"
              onClickHandler={onApplyFilterBtnHandler}
            />
          </Box>
        </Drawer>
      </div>
    </div>
  );
};
