export const IconNotFoundPage = () => (
  <svg
    width="667.000000"
    height="225.799805"
    viewBox="0 0 667 225.8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M442.68 183.46C442.68 206.84 394.51 225.8 335.08 225.8C275.67 225.8 224.31 206.84 224.31 183.46C224.31 160.08 275.67 141.12 335.08 141.12C394.5 141.12 442.68 160.08 442.68 183.46ZM332.26 65.85C285.21 65.85 247.08 80.6 247.08 98.78C247.08 116.97 285.21 131.71 332.26 131.71C379.31 131.71 421.57 116.97 421.57 98.78C421.57 80.6 379.31 65.85 332.26 65.85ZM321.64 0C294.39 0 269.14 12.63 269.14 28.22C269.14 43.81 294.4 56.45 321.64 56.45C348.88 56.45 370.95 43.81 370.95 28.22C370.95 12.63 348.88 0 321.64 0Z"
      fill="#00ED51"
      fillOpacity="1.000000"
      fillRule="nonzero"
    />
    <path
      id="4"
      d="M0 178.38L0 143.22L106.5 0L161.37 0L57.12 143.22L31.62 135.8L209.45 135.8L209.45 178.38L0 178.38ZM121.67 225.8L121.67 178.38L123.28 135.8L123.28 93.54L172.66 93.54L172.66 225.8L121.67 225.8Z"
      fill="#000000"
      fillOpacity="1.000000"
      fillRule="nonzero"
    />
    <path
      id="5544"
      d="M457.54 178.38L457.54 143.22L564.04 0L618.91 0L514.66 143.22L489.17 135.8L667 135.8L667 178.38L457.54 178.38ZM579.21 225.8L579.21 178.38L580.82 135.8L580.82 93.54L630.2 93.54L630.2 225.8L579.21 225.8Z"
      fill="#000000"
      fillOpacity="1.000000"
      fillRule="nonzero"
    />
  </svg>
);
