// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-button {
  border: 1px solid black !important;
  min-width: auto;
  height: 50px !important;
  font-weight: 600 !important;
  font-family: inherit !important;
  text-transform: uppercase;
}

.Mui-disabled {
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/shared/CommonButton/index.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;EACA,uBAAA;EACA,2BAAA;EACA,+BAAA;EACA,yBAAA;AACF;;AACA;EACE,uBAAA;AAEF","sourcesContent":[".common-button {\n  border: 1px solid black !important;\n  min-width: auto;\n  height: 50px !important;\n  font-weight: 600 !important;\n  font-family: inherit !important;\n  text-transform: uppercase;\n}\n.Mui-disabled {\n  color: black !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
